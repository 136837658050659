import axios from "../api/axios";
import { qaApiUrl } from "../utils/config";

const fetchAddEmailToWaitList = async (email, landingId) => {
  try {
    const linkClickId = localStorage.getItem("linkClickId") || undefined;
    const payload = { email, linkClickId };

    if (landingId) {
      payload.landingPageId = landingId;
    }

    const response = await axios.post("/wait-list", JSON.stringify(payload));

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    localStorage.removeItem("linkClickId");

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data?.message || error.message,
    };
  }
};

const fetchValidateResetToken = async (token, isQaEnv) => {
  const config = {};
  if (isQaEnv) {
    config.baseURL = qaApiUrl;
  }
  try {
    const response = await axios.post(
      "/recover/validate",
      { rt: token },
      config,
    );

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data?.message || error.message,
    };
  }
};

const fetchVerifyEmail = async (data, isQaEnv) => {
  const config = {};
  if (isQaEnv) {
    config.baseURL = qaApiUrl;
  }
  try {
    const response = await axios.post("/confirm-email", data, config);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchPosts = async (params, isQaEnv) => {
  const config = {};
  if (isQaEnv) {
    config.baseURL = qaApiUrl;
  }
  try {
    const response = await axios.get(`/posts`, {
      ...config,
      params,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchPostByLink = async (link, isQaEnv) => {
  const config = {};
  if (isQaEnv) {
    config.baseURL = qaApiUrl;
  }
  try {
    const response = await axios.get(`/posts/${link}`, config);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchLandingPage = async (slug, isQaEnv) => {
  try {
    const config = {};
    if (isQaEnv) {
      config.baseURL = qaApiUrl;
    }

    const params = slug ? { slug } : {};
    const response = await axios.get("/landing-pages", { ...config, params });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetMegaPages = async (params, isQaEnv) => {
  try {
    const config = {};
    if (isQaEnv) {
      config.baseURL = qaApiUrl;
    }

    const response = await axios.get(`/seo/mega-pages`, {
      ...config,
      params,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetMegaPage = async (link, isQaEnv) => {
  try {
    const config = {};
    if (isQaEnv) {
      config.baseURL = qaApiUrl;
    }

    const response = await axios.get(`/seo/mega-pages/${link}`, { ...config });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetMegaPageData = async (id, isQaEnv) => {
  try {
    const config = {};
    if (isQaEnv) {
      config.baseURL = qaApiUrl;
    }

    const response = await axios.get("/seo/data", {
      ...config,
      params: { megaPageId: id },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchFeaturedPost = async (isQaEnv) => {
  try {
    const config = {};
    if (isQaEnv) {
      config.baseURL = qaApiUrl;
    }

    const response = await axios.get("/posts/featured", config);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

export {
  fetchAddEmailToWaitList,
  fetchValidateResetToken,
  fetchVerifyEmail,
  fetchPosts,
  fetchPostByLink,
  fetchLandingPage,
  fetchGetMegaPages,
  fetchGetMegaPage,
  fetchGetMegaPageData,
  fetchFeaturedPost,
};
