import styled from "styled-components";

export const Container = styled.div`
  height: 510px;
  border-radius: 24px;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 32px -12px #0e121b1a;
  text-decoration: none;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImageContainer = styled.div`
  margin: 24px 24px 0 24px;
  width: auto;
  height: 270px;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

export const Content = styled.div`
  margin: 24px;
`;

export const Tag = styled.span`
  min-height: 28px;
  display: inline-block;
  background-color: #ebf3ff;
  color: #3e89ff;
  padding: 4px 8px;
  border-radius: 8px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

export const Title = styled.a`
  margin-top: 16px;
  color: #0e121b;
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  height: 96px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Meta = styled.p`
  margin-top: 12px;
  display: flex;
  align-items: center;
  color: #717784;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const MetaSeparator = styled.span`
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 50%;
  margin: 0 8px;
`;
