import React from "react";
import PropTypes from "prop-types";
import { PromoDescription } from "./styles";
import PromoSection from "../../../../components/PromoSection";
import EmailSubscribeForm from "../../components/EmailSubscribeForm";
import Rating from "../Rating";

const Subscribe = ({ landingId, onSubscribe }) => {
  return (
    <PromoSection
      Description={() => (
        <PromoDescription>
          <EmailSubscribeForm
            landingId={landingId}
            onSuccess={onSubscribe}
            buttonText="Try Reach"
          />
          <Rating />
        </PromoDescription>
      )}
      isBigTitle={true}
    />
  );
};

Subscribe.propTypes = {
  landingId: PropTypes.string.isRequired,
};

export default Subscribe;
