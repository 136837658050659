import styled, { css } from "styled-components";

export const FooterContainer = styled.div`
  margin: 0 auto;
  padding: 62px 0 64px;
  width: 1200px;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

export const TopContent = styled.div`
  padding: 0 0 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const BottomContent = styled.div`
  padding: 40px 0 0;
  width: 100%;
  border-top: 1px solid #3919521f;
  display: flex;
  flex-direction: row;
  column-gap: 34px;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    row-gap: 24px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  ${({ $top }) =>
    $top
      ? css`
          column-gap: 34px;
        `
      : css``}

  &,
  * {
    color: #39195280;
    font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const LicenseContainer = styled.div`
  color: #39195280;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
`;

export const Logo = styled.img.attrs({
  src: "/images/full-logo-pink.png",
  alt: "Reach by SalesStream.ai logo - Free AI Email Generator",
})`
  width: 105px;
`;
