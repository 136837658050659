import styled, { css } from "styled-components";

export const Root = styled.div`
  width: 100%;
  margin: 80px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Title = styled.h2`
  font-family: Poppins;
  font-weight: 700;
  font-size: 64px;
  line-height: 78.08px;
  letter-spacing: 0%;
  text-align: center;
  margin: 0 auto;

  ${({ variant }) =>
    variant === "framed"
      ? css`
          font-size: 48px;
          line-height: 1.25em;
          max-width: 600px;
          color: #391952;

          @media (max-width: 768px) {
            font-size: 40px;
          }
        `
      : css`
          color: #000;
        `}
`;

export const Subtitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  color: #391952;
  max-width: 600px;
  margin: -20px 0 0;
  opacity: 0.7;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  flex-wrap: wrap;
  margin-top: 20px;

  ${({ variant }) =>
    variant === "framed" &&
    css`
      gap: 24px;

      @media (max-width: 768px) {
        gap: 16px;
      }
    `}
`;

export const IconWrapper = styled.div`
  height: auto;
  opacity: 0.8;
  transition: all 0.2s ease-in-out;

  ${({ variant }) =>
    variant === "framed"
      ? css`
          background: #ffffff;
          border-radius: 12px;
          padding: 24px 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 232px;
          height: 150px;
          box-shadow: 0px 16px 32px -12px #0e121b1a;

          &:hover {
            opacity: 1;
            transform: translateY(-2px);
          }

          @media (max-width: 768px) {
            width: 163.5px;
            height: 100px;
          }
        `
      : css`
          &:hover {
            opacity: 1;
          }
        `}

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
