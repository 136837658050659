import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FooterContainer,
  BottomContent,
  LicenseContainer,
  Links,
  TopContent,
  Logo,
} from "./styles";

const Footer = () => {
  const navigate = useNavigate();

  const fullYear = new Date().getFullYear();

  const goTo = (path) => {
    navigate(path);
  };

  const footerTitles = [];

  return (
    <FooterContainer>
      <TopContent>
        <Logo />
        {!!footerTitles.length && (
          <Links $top={true}>
            {footerTitles.map((footerTitle, idx) => (
              <span key={idx}>{footerTitle}</span>
            ))}
          </Links>
        )}
      </TopContent>
      <BottomContent>
        <LicenseContainer>
          Copyright © {fullYear} Reach. All rights reserved
        </LicenseContainer>
        <Links>
          <span onClick={() => goTo("/privacy")}>Privacy Policy</span>
          <span onClick={() => goTo("/terms-conditions")}>Terms of Use</span>
        </Links>
      </BottomContent>
    </FooterContainer>
  );
};

export default Footer;
