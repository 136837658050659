import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 45px;
  background-color: #000000;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 45px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 45px;
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  right: -52px;
  bottom: -38px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  z-index: 2;
  transform-origin: bottom right;

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 199px;
    height: 202px;
    display: block;
  }
`;

export const Video = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 45px;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 45px;
  }
`;
