import styled, { css } from "styled-components";

export const CategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;

  @media (max-width: 767px) {
    padding: 0 9px;

    ${({ $isOpen }) =>
      $isOpen
        ? css``
        : css`
            display: none;
          `}
  }
`;

export const CategoryButtons = styled.div`
  width: 68%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0;
    width: calc(100% - 9px * 2);
    padding: 11px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    background: #ffffff;
    box-shadow: 0px 16px 56px 0px #00000026;
    position: absolute;
    top: 40px;
    left: 9px;
    z-index: 1;
  }
`;

export const CategoryButton = styled.div`
  padding: 7px 16px;
  width: fit-content;
  cursor: pointer;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${({ $isActive }) => ($isActive ? "#FFFFFF" : "#0A0A0A")};
  background: ${({ $isActive }) => ($isActive ? "#0e121b" : "transparent")};
  box-shadow: ${({ $isActive }) =>
    $isActive ? "0px 1px 2px 0px #0e121b08" : "none"};
  border: 1px solid #0e121b;
  border-radius: 12px;
  outline: none;

  &:hover {
    opacity: ${({ $isActive }) => ($isActive ? 0.9 : 0.8)};
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 12px;
    border: none;
    box-shadow: none;
    background: ${({ $isActive }) => ($isActive ? "#F6F6F6" : "transparent")};
    color: #0d0d12;
  }
`;

export const CategoriesDropdownContainer = styled.div`
  margin-bottom: 24px;

  @media (max-width: 767px) {
    margin-bottom: -8px;
    padding: 0 9px;
  }
`;

export const CategoriesDropdownTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const CategoriesDropdownButton = styled.div`
  margin-top: 24px;
  padding: 11px 40px 11px 12px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #0d0d12;
  position: relative;

  &::after {
    content: "";
    background-image: url("/images/chevron-down-icon-black.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    position: absolute;
    top: calc((100% - 20px) / 2);
    right: 11px;
    width: 20px;
    height: 20px;
    transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
    transition: transform 0.2s ease;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
