import React from "react";

import { Container, Cursor, SelectionArea } from "./styles";

const Animation = ({ isStarted }) => {
  return (
    <Container $isStarted={isStarted}>
      <img
        src="/images/feat-3-new.svg"
        alt="Reach by SalesStream.ai - Free AI Email Generator - Send personalized email from Spreadsheet"
      />
      <Cursor />
      <SelectionArea />
    </Container>
  );
};

export default Animation;
