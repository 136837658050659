import React from "react";

import { Container, Content, Image, ImageContainer } from "./styles";

function Preview() {
  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image
            src="/images/landing-features/marketing-power-4/preview.png"
            alt="Marketing Power - Hubspot Email Personalization Preview"
          />
        </ImageContainer>
      </Content>
    </Container>
  );
}

export default Preview;
