import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fffcf5;
  position: relative;
`;

export const HeroSection = styled.div`
  color: #ffffff;
  background-color: #391952;
  position: relative;
  width: 100%;
  height: 955px;
  padding: 184px 15px 0 15px;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 768px) {
    height: 740px;
    padding-top: 96px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -90px;
      height: 90px;
      width: 100%;
      background-color: #fff;
    }
  }

  @media (max-width: 768px) {
    height: 710px;
    padding-top: 96px;

    &::after {
      bottom: -84px;
      height: 84px;
    }
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const StaticPromoSectionContainer = styled.div`
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const SquiggleImage = styled.img`
  display: block;
  z-index: 1;
  object-fit: cover;
  position: absolute;
  right: 0;
  bottom: calc(29%);
  max-width: 20vw;
  max-height: 30vh;
  width: auto;
  height: auto;

  @media (max-width: 977px) {
    display: none;
  }
`;
