export const ROTATION_WORD_LIST = [
  { id: 1, titleWord: "sales", descriptionWord: "startups" },
  { id: 2, titleWord: "email", descriptionWord: "solopreneurs" },
  { id: 3, titleWord: "marketing", descriptionWord: "freelancers" },
  { id: 4, titleWord: "SMS", descriptionWord: "dentists" },
  { id: 5, titleWord: "loyalty", descriptionWord: "restaurants" },
  { id: 6, titleWord: "outreach", descriptionWord: "all of us" },
];

export const RECOMMENDATION_FEATURES = [
  { Personalization: "personalization" },
  { Scalability: "scalability" },
  { "Content Quality": "content_quality" },
  { "CRM Integration": "crm_integration" },
  { "Multi-Channel Outreach": "multi_channel_outreach" },
  { "Campaign Management": "campaign_management" },
  { "Ease of Use": "ease_of_use" },
  { Automation: "automation" },
  { Insight: "insight" },
  { "Learning Curve": "learning_curve" },
];

export const FEATURE_CARDS_INFO = [
  {
    backgroundColor: "#F5AE52",
    title: "Always write the perfect message – without writing a thing ",
    text: "Tell Reach your campaign goals and Reach will communicate with each of your contacts on your behalf",
  },
  {
    backgroundColor: "#FECE32",
    title: "Don’t sacrifice quality for quantity",
    text: "Reach will create and personalize every message in your campaign automatically the way you would if you had the time",
  },
  {
    backgroundColor: "#E0C2F2",
    title: "Seamless CRM Integration",
    text: "Reach integrates seamlessly with leading CRMs like Hubspot and Bullhorn, giving you the power to execute personalized outreach campaigns directly from your CRM data.",
  },
  {
    backgroundColor: "#92ABFC",
    title: "No CRM? No problem!",
    text: "Simply highlight your data in a spreadsheet and get started. Reach will analyze your data and help you go from a spreadsheet to an expert-level email & SMS campaign in just a few clicks.",
  },
];

export const MOBILE_BREAKPOINT = 768;

export const REACH_COMPANY = {
  title: "Reach",
  personalization: "Every message completely personalized",
  scalability: "Highly scalable",
  content_quality: "Uniquely tailored branded message",
  crm_integration: "Lives inside your CRM",
  multi_channel_outreach: "Email & SMS campaigns",
  campaign_management: "Fully automated",
  ease_of_use: "No setup, no training",
  automation: "Simple, customizable sequences",
  insight: "Detailed, actionable analytics",
  learning_curve: "Almost Zero",
};

export const OTHER_COMPANY = [
  {
    title: "Email Marketing Platforms",
    personalization: "'Mail-merge' style personalization",
    scalability: "Highly scalable",
    content_quality: "Redundant, generic templates",
    crm_integration: "Manual integration/data sync",
    multi_channel_outreach: "Email only",
    campaign_management: "Semi-automated",
    ease_of_use: "Needs CRM familiarity",
    automation: "Basic sequences",
    insight: "Limited metrics",
    learning_curve: "Low to Moderate",
  },
  {
    title: "CRM Co-Pilots",
    personalization: "Requires manual adjustments",
    scalability: "One message at a time",
    content_quality: "Good",
    crm_integration: "Lives inside your CRM",
    multi_channel_outreach: "Email-only, manual sends",
    campaign_management: "Manual via external tool",
    ease_of_use: "Moderate, manual integration is needed",
    automation: "No automation",
    insight: "Limited insights",
    learning_curve: "Moderate",
  },
  {
    title: "ChatGPT",
    personalization: "User-provided input; no CRM data",
    scalability: "One message at a time",
    content_quality: "As good as your prompt",
    crm_integration: "No direct CRM integration",
    multi_channel_outreach: "Content for any channels",
    campaign_management: "Manual via external tool",
    ease_of_use: "Flexible but labor-intensive",
    automation: "No automation",
    insight: "None",
    learning_curve: "Moderate",
  },
];
