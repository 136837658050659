import React from "react";

import { calcReadTime, displayDateShort } from "../../../../../utils/utils";

import {
  Container,
  Title,
  Card,
  Content,
  Image,
  ImageContainer,
  Meta,
  Tag,
  MetaSeparator,
} from "./styles";

function Article({
  heroImage,
  tag,
  title,
  publishDate,
  text,
  link,
  openBlogsByTag,
}) {
  const handleClickByTag = (e) => {
    e?.preventDefault();
    openBlogsByTag(tag);
  };

  return (
    <Container>
      <Card>
        <ImageContainer>
          <Image src={heroImage} alt="Hero Image" />
        </ImageContainer>
        <Content>
          <Tag onClick={handleClickByTag}>{tag}</Tag>
          <Title href={`/blog/${link}`}>{title}</Title>
          <Meta>
            {displayDateShort(publishDate)} <MetaSeparator />
            {calcReadTime(text)} min read
          </Meta>
        </Content>
      </Card>
    </Container>
  );
}

export default Article;
