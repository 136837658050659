import React from "react";
import Title from "./Title";
import Search from "./Search";
import { Container, Description } from "./styles";

function Benefits({ onSearch, isResetSearch, onChangeSearch, megaPage }) {
  return (
    <Container>
      <Title headerText={megaPage.pageHeaderText} />
      <Description>
        Get Answers to all your{" "}
        {megaPage.pageHeaderText?.toLowerCase() || "email marketing"} queries in
        one place
      </Description>
      <Search {...{ onSearch, isResetSearch, onChangeSearch }} />
    </Container>
  );
}

export default Benefits;
