import { FIXED_HEADER_PATHS } from "../../../utils/constants";

export const shouldPositionHeaderAbsolute = (pathname) => {
  return FIXED_HEADER_PATHS.some((path) => pathname.startsWith(path));
};

export const isLandingPage = (pathname) => {
  return pathname.includes("/l/");
};

export const shouldShowReachButton = (isForHubspot, isForLanding) => {
  return isForHubspot || !isForLanding;
};

export const getHeaderConditions = ({
  pathname,
  isForHubspot,
  isForLanding,
}) => {
  const isLanding = isLandingPage(pathname);

  return {
    shouldAbsolutePositionHeader: shouldPositionHeaderAbsolute(pathname),
    isLanding,
    shouldShowReachButton: shouldShowReachButton(isForHubspot, isForLanding),
    isWide: isForLanding,
  };
};
