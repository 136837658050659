import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  position: ${({ $shouldAbsolutePositionHeader }) =>
    !$shouldAbsolutePositionHeader ? "fixed" : "absolute"};
  top: ${({ $isNavbarVisible, $isForHubspot }) =>
    $isForHubspot ? "20px" : "40px"};
  left: 0;
  right: 0;
  padding: 0 10px;
  z-index: 2;

  @media (max-width: 977px) {
    padding-left: 0;
    padding-right: 0;
    top: 0;
  }
`;

export const Container = styled.div`
  background-color: transparent;
  width: ${({ $isWide }) => ($isWide ? "100%" : "1210px")};
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  height: 56px;
  position: relative;
  transition: all 0.3s ease;

  ${({ $isFlat, $isWide }) =>
    $isFlat && !$isWide
      ? css`
          @media (max-width: 977px) {
            background-color: #fff;
            height: 63px;
            border: 1px solid #f3f3f3;
        `
      : css``}

  @media (max-width: 1440px) {
    width: 100%;

    ${({ $isWide }) =>
      !$isWide &&
      css`
        padding-left: 7%;
        padding-right: 7%;
      `}
  }

  @media (max-width: 1170px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 977px) {
    height: calc(36px + 24px * 2);

    ${({ $isFlat }) =>
      $isFlat
        ? css`
            height: 63px;
          `
        : css``}
  }

  .isSticky & {
    background-color: #fff;
    box-shadow: 0px 16px 32px -12px #00000026;
    border-radius: 8px;

    @media (max-width: 977px) {
      border-radius: 0px;
    }
  }
`;

export const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 5px 0 24px;

  ${({ $isFlat, $isWide }) =>
    $isFlat && !$isWide
      ? css`
          background-color: #fff;
          -webkit-box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
          box-shadow: 0px 10px 27px -9px rgba(34, 60, 80, 0.2);
          padding: 0 22px;
        `
      : css``}

  ${({ $isWide }) =>
    $isWide &&
    css`
      padding: 0px 22px;
    `} 

  @media (max-width: 977px) {
    box-shadow: none;
    padding: 0 24px;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Logo = styled.div`
  width: 105px;
  height: 100%;
  background-image: ${({ $isFlat, $isWide }) =>
    $isFlat
      ? "url('/images/full-logo-new.png')"
      : !$isWide
      ? "url('/images/full-logo-new.png')"
      : "url('/images/full-logo-pink.png')"};
  background-size: 105px 33px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s ease;

  .isSticky & {
    background-image: url("/images/full-logo-pink.png");
  }
`;

export const TabsContainer = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px 0
    ${({ $isNavbarVisible }) => (!$isNavbarVisible ? "5px" : "22px")};

  ${({ $isFlat }) =>
    $isFlat
      ? css`
          background-color: #fffcf5;
        `
      : css``}

  @media (max-width: 977px) {
    flex-direction: row-reverse;
    box-shadow: none;
  }
`;

export const TryLink = styled.a`
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin: 0 24px 0 5px;
  background-color: #fff;
  color: #612686;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  transition: all 0.3s ease;

  @media (min-width: 978px) {
    padding: 8px 21px;
    border-radius: 8px;
    ${({ $forMobile }) =>
      $forMobile
        ? css`
            display: none;
          `
        : css``}
  }

  @media (max-width: 977px) {
    padding: 6px 17px;
    margin: 0 12px;
    border-radius: 8px;
    ${({ $forMobile }) =>
      $forMobile
        ? css``
        : css`
            display: block;
          `}
  }

  .isSticky & {
    background-color: #612686;
    color: #fff;
  }
`;

export const InstallAppLink = styled.a`
  text-decoration: none;
  display: inline-block;
  background-color: #ffdc61;
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  color: #391952;
  padding: 11.8px
    ${({ $isNavbarVisible }) => (!$isNavbarVisible ? "21.5px" : "34px")};
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 4px 16px;
    margin-right: 9px;
    font-size: 12px;
    font-weight: 800;
  }
`;

export const HamburgerMenu = styled.img.attrs({
  src: "/images/hamburger-menu-white.svg",
  alt: "menu",
})`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.45s ease-in-out;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          background: #612686;
          box-shadow: 0 0 0 7px #612686;
        `
      : css`
          box-shadow: 0 0 0 7px transparent;
        `}

  @media (min-width: 978px) {
    display: none;
  }

  .isSticky & {
    content: url("/images/hamburger-menu-black.svg");
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 24px;

  ${({ $isWide }) =>
    $isWide &&
    css`
      padding: 0px 5px;
    `}
`;
