import React from "react";
import {
  PromoContainer,
  PromoContent,
  PromoTitle,
  PromoText,
  AnimationContainer,
  TryButton,
  TextContainer,
} from "./styles";
import ThirdAnimation from "../../pages/HomeLanding/Features/Card/Animation/Third";
import { CHROME_STORE_EXTENSION_URL } from "../../utils/constants";
import { useInView } from "react-intersection-observer";

const PromoSection = ({
  Description,
  isBigTitle = false,
  forResource = false,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const DefaultDescription = () => (
    <>
      <PromoText>
        Tell Reach your campaign goals and Reach will communicate with each of
        your contacts on your behalf
      </PromoText>
      <TryButton href={CHROME_STORE_EXTENSION_URL} target="_blank">
        Start Your Free Trial
      </TryButton>
    </>
  );

  return (
    <PromoContainer $forResource={forResource} ref={ref}>
      <PromoContent $forResource={forResource}>
        <TextContainer $forResource={forResource}>
          <PromoTitle $isBigTitle={isBigTitle} $forResource={forResource}>
            Create Personalized email campaigns from your{" "}
            <span>spreadsheet for Free</span>
          </PromoTitle>
          {Description?.() || <DefaultDescription />}
        </TextContainer>
        <AnimationContainer $forResource={forResource}>
          <ThirdAnimation isStarted={inView} forResource={forResource} />
        </AnimationContainer>
      </PromoContent>
    </PromoContainer>
  );
};

export default PromoSection;
