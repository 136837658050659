import React from "react";
import { StepsContainer } from "./styles";
import StepContent from "./step";

const DefaultVariant = ({ stepsInfo, variant }) => (
  <StepsContainer variant={variant}>
    {stepsInfo.steps.map((step, index) => (
      <StepContent
        key={index}
        step={step}
        index={index}
        imagesPath={stepsInfo.imagesPath}
        variant={variant}
      />
    ))}
  </StepsContainer>
);

export default DefaultVariant;
