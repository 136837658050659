import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const StepNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 16px;
  background: #391952;
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: -100px;
    left: 24px;
    width: 0;
    height: 185px;
    z-index: -1;
    border-left: 2px solid;
    border-image-source: linear-gradient(
      180deg,
      rgba(57, 25, 82, 0) 0%,
      #391952 51.5%,
      rgba(57, 25, 82, 0) 100%
    );
    border-image-slice: 1;
  }
`;

export const Title = styled.h3`
  padding-top: 54px;
  font-family: Inter;
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: -3%;
  text-align: center;
  color: #0a0a0a;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 36px;
  max-width: 750px;
  max-height: 450px;
  aspect-ratio: 750 / 450;
  width: 100%;
  box-shadow: 0px 24px 48px -18px #0e121b1a;

  @media (max-width: 768px) {
    border-radius: 16px;
    box-shadow: 0px 10.46px 20.93px -7.85px #0e121b1a;
    max-width: none;
    max-height: none;
    height: auto;
    aspect-ratio: auto;
  }
`;

export const ImageContent = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 4px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
`;
