import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const SubmenuWrapper = styled.div`
  ${({ $isSubmenuVisible }) =>
    $isSubmenuVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  position: absolute;
  left: 0;
  right: 0;
  top: 120%;
  width: 100%;

  @media (max-width: 1440px) {
    padding-left: 7.4%;
    padding-right: 7.4%;
  }
`;

export const SubmenuContainer = styled.div`
  border-radius: 16px;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 40px 92px 40px 112px;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-shadow: 0px 40px 120px -0.5px #0f0f1026;
  border: 1px solid rgba(255, 255, 255, 0.18);

  &::before {
    content: "";
    display: block;
    width: 262px;
    height: 262px;
    position: absolute;
    top: -146px;
    left: -50px;
    background: radial-gradient(circle, #f9ac59 0%, rgba(249, 172, 89, 0) 70%);
    border-radius: 100%;
    filter: blur(20px);
    z-index: -1;
  }

  &::after {
    content: "";
    display: block;
    width: 262px;
    height: 262px;
    position: absolute;
    bottom: -164px;
    right: -114px;
    background: radial-gradient(circle, #ffdc61 0%, rgba(255, 220, 97, 0) 70%);
    border-radius: 100%;
    filter: blur(20px);
    z-index: -1;
  }
`;

export const SubmenuContentContainer = styled.div`
  display: flex;
`;

export const SubmenuMainInfo = styled.div`
  max-width: 363px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SubmenuTitle = styled.h3`
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
`;

export const SubmenuDescription = styled.p`
  margin-top: 20px;
  font-family: Inter;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
`;

export const SubmenuLink = styled(Link)`
  margin-top: 32px;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  color: #000;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url("/images/arrow-right-black.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 4px;
  }
`;

export const SubmenuTabList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 12px;
  padding-right: 12px;
  gap: 12px;

  ${({ $isFeaturedPostVisible }) =>
    $isFeaturedPostVisible &&
    css`
      flex-direction: column;
    `}
`;

export const SubmenuTabItem = styled.li`
  border-radius: 12px;
  padding: 12px 44px 12px 12px;
  margin: 0;
  position: relative;
  height: 85px;
  width: 312px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);

    &::after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url("/images/arrow-right-black.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const SubmenuTabLink = styled(Link)`
  padding: 0;
  margin: 0;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
`;

export const SubmenuTabIcon = styled.img``;

export const SubmenuLinkContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  gap: 4px;
`;

export const SubmenuTabTitle = styled.h4`
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #0e121b;
`;

export const SubmenuTabDescription = styled.p`
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #4f4f4f;
`;
