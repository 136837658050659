import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import Article from "./Article";
import { useSwiperConfig } from "./hooks";

import "swiper/css";
import "swiper/css/navigation";
import {
  Container,
  Title,
  ArticlesContainer,
  NavigationContainer,
  NavButton,
  PageIndicator,
  SwiperStyled,
  FakeSlide,
} from "./styles";

const MOBILE_BREAKPOINT = 768;
const TABLET_BREAKPOINT = 1200;

const NavigationControls = ({ currentPage, totalSlides, onPrev, onNext }) => (
  <NavigationContainer>
    <NavButton onClick={onPrev} disabled={currentPage === 1}>
      <img src="/images/arrow-left-black.svg" alt="Previous articles" />
    </NavButton>
    <PageIndicator>
      {currentPage}/{totalSlides}
    </PageIndicator>
    <NavButton onClick={onNext} disabled={currentPage === totalSlides}>
      <img src="/images/arrow-right-black.svg" alt="Next articles" />
    </NavButton>
  </NavigationContainer>
);

const RelatedArticles = ({ relatedArticles, openBlogsByTag }) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_BREAKPOINT });
  const isTablet = useMediaQuery({ maxWidth: TABLET_BREAKPOINT });
  const swiperRef = useRef(null);

  const {
    slidesPerView,
    totalSlides,
    currentPage,
    setCurrentPage,
    calculatePageFromSlideIndex,
  } = useSwiperConfig(relatedArticles, isMobile, isTablet);

  const handleSlideChange = (swiper) => {
    setCurrentPage(calculatePageFromSlideIndex(swiper.activeIndex));
  };

  const slideNext = () => swiperRef.current?.swiper?.slideNext();
  const slidePrev = () => swiperRef.current?.swiper?.slidePrev();

  const fakeSlidesCount =
    (slidesPerView - (relatedArticles.length % slidesPerView)) % slidesPerView;

  return (
    <Container>
      <Title>Related Articles</Title>

      <NavigationControls
        currentPage={currentPage}
        totalSlides={totalSlides}
        onPrev={slidePrev}
        onNext={slideNext}
      />

      <ArticlesContainer>
        <SwiperStyled
          ref={swiperRef}
          slidesPerView={slidesPerView}
          slidesPerGroup={slidesPerView}
          spaceBetween={24}
          onSlideChange={handleSlideChange}
          modules={[Navigation]}
          allowSlideNext={slidesPerView > 0}
          allowSlidePrev={true}
          loop={false}
        >
          {relatedArticles.map((article) => (
            <SwiperSlide key={article.id}>
              <Article
                heroImage={article.heroImage}
                tag={article.tag}
                title={article.title}
                publishDate={article.publishDate}
                text={article.text}
                link={article.link}
                openBlogsByTag={openBlogsByTag}
              />
            </SwiperSlide>
          ))}
          {fakeSlidesCount > 0 && getFakeSlides(fakeSlidesCount)}
        </SwiperStyled>
      </ArticlesContainer>
    </Container>
  );
};

function getFakeSlides(count) {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <FakeSlide key={`empty-${index}`} />
      ))}
    </>
  );
}

export default RelatedArticles;
