import React from "react";

import {
  FeaturedPostContainer,
  ImageContainer,
  Image,
  Content,
  Tag,
  Title,
  ReadMoreLink,
} from "./styles";

const FeaturedBlogPost = ({ featuredPost, isFeaturedPostVisible }) => {
  const { heroImage, tag, title, link } = featuredPost;

  return (
    <FeaturedPostContainer $isFeaturedPostVisible={isFeaturedPostVisible}>
      <ImageContainer>
        <Image src={heroImage} alt="Hero Image" />
      </ImageContainer>
      <Content>
        <Tag>{tag}</Tag>
        <Title>{title}</Title>
        <ReadMoreLink to={`/blog/${link}`}>Read More</ReadMoreLink>
      </Content>
    </FeaturedPostContainer>
  );
};

export default FeaturedBlogPost;
