import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import TabsList from "./TabList";
import { NAVIGATION_TAB_LIST } from "../../utils/constants";
import { NavigationContext } from "../../contexts/NavigationContext";
import { useHeaderScroll, useClickOutside, useNavigation } from "./hooks";
import { MainHeaderProvider } from "./context/MainHeaderContext";
import ReachButton from "./components/ReachButton";
import { getHeaderConditions } from "./utils/headerConditions";

import {
  Container,
  Logo,
  LogoContainer,
  TabsContainer,
  Wrapper,
  HamburgerMenu,
  ActionButton,
  LogoLink,
} from "./styles";

function MainHeader({
  isForLanding = false,
  isForHubspot = false,
  onSubscribe,
  isExtensionInstalled = false,
}) {
  const location = useLocation();
  const { isNavbarVisible } = useContext(NavigationContext);
  const { selectedTab, handleTabClick } = useNavigation();

  const {
    shouldAbsolutePositionHeader,
    isLanding,
    shouldShowReachButton,
    isWide,
  } = getHeaderConditions({
    pathname: location.pathname,
    isForHubspot,
    isForLanding,
  });

  const wrapperRef = useRef(null);
  const tabListRef = useRef(null);
  const [isOpenTabList, setIsOpenTabList] = useState(false);

  useHeaderScroll(wrapperRef, isNavbarVisible);
  useClickOutside(tabListRef, () => setIsOpenTabList(false), ["HamburgerMenu"]);

  const toggleTabList = () => setIsOpenTabList(!isOpenTabList);

  useEffect(() => {
    setIsOpenTabList(false);
  }, [location.pathname]);

  if (isLanding && !isForLanding) {
    return null;
  }

  return (
    <MainHeaderProvider>
      <Wrapper
        ref={wrapperRef}
        $isNavbarVisible={isNavbarVisible}
        $isForHubspot={isForHubspot}
        $shouldAbsolutePositionHeader={shouldAbsolutePositionHeader}
        $isFlat={!isForHubspot && isWide}
      >
        <Container $isWide={isWide} $isFlat={!isForHubspot && isWide}>
          <LogoContainer $isFlat={!isForHubspot && isWide} $isWide={isWide}>
            <LogoLink to="/">
              <Logo
                role="img"
                aria-label="Reach by SalesStream.ai logo - Free AI Email Generator"
                $isFlat={!isForHubspot && isWide}
                $isWide={isWide}
              />
            </LogoLink>
          </LogoContainer>

          {(isNavbarVisible || shouldShowReachButton) && (
            <TabsContainer
              $isNavbarVisible={isNavbarVisible}
              $isFlat={isForHubspot}
            >
              {isNavbarVisible && (
                <TabsList
                  ref={tabListRef}
                  isOpen={isOpenTabList}
                  list={NAVIGATION_TAB_LIST}
                  selectedTab={selectedTab}
                  handleTabClick={handleTabClick}
                  setIsOpenTabList={setIsOpenTabList}
                />
              )}
            </TabsContainer>
          )}

          <ActionButton $isWide={isWide}>
            {shouldShowReachButton && (
              <ReachButton
                isForHubspot={isForHubspot}
                isExtensionInstalled={isExtensionInstalled}
                onSubscribe={onSubscribe}
                forMobile={false}
                isNavbarVisible={isNavbarVisible}
              />
            )}
            {!isLanding && (
              <HamburgerMenu
                className="HamburgerMenu"
                onClick={toggleTabList}
                $isOpen={isOpenTabList}
              />
            )}
          </ActionButton>
        </Container>
      </Wrapper>
    </MainHeaderProvider>
  );
}

export default MainHeader;
