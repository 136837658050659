import { fetchAddEmailToWaitList } from "./queries.js";

const FORCE_CORPORATE_EMAILS = false;

const addEmailToWaitList = async (email, landingId) => {
  console.log(landingId);

  const result = await fetchAddEmailToWaitList(email, landingId);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { success: true, recordsCount: result.res };
};

export { addEmailToWaitList, FORCE_CORPORATE_EMAILS };
