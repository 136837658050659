import React, { forwardRef, useEffect, useState } from "react";

import { Item, Name, StyledLink, StyledMenuItem } from "./styles";

import SubMenu from "../../SubMenu";

const TabItem = forwardRef(
  (
    {
      name,
      link,
      onClick,
      submenu = null,
      tabRef: tabItemRef,
      isActive,
      setIsOpenTabList,
    },
    ref,
  ) => {
    const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

    useEffect(() => {
      function handleClickOutside(event) {
        if (tabItemRef && !tabItemRef.contains(event.target)) {
          setIsSubmenuVisible(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [tabItemRef]);

    const handleClickMenuItem = () => {
      setIsSubmenuVisible(!isSubmenuVisible);
    };

    const renderSubmenuItem = () => (
      <StyledMenuItem
        onClick={handleClickMenuItem}
        $isActive={isActive}
        $isSubmenuVisible={isSubmenuVisible}
      >
        <Name $hasSubmenu={true} $isSubmenuVisible={isSubmenuVisible}>
          {name}
        </Name>
        <SubMenu
          data={submenu}
          setIsOpenTabList={setIsOpenTabList || setIsSubmenuVisible}
          isActive={isActive}
          isSubmenuVisible={isSubmenuVisible}
        />
      </StyledMenuItem>
    );

    const renderLinkItem = () => (
      <StyledLink to={link} $isActive={isActive}>
        <Name>{name}</Name>
      </StyledLink>
    );

    return (
      <Item ref={ref} onClick={onClick}>
        {submenu ? renderSubmenuItem() : renderLinkItem()}
      </Item>
    );
  },
);

export default TabItem;
