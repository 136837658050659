import styled from "styled-components";

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  position: relative;

  @media (max-width: 768px) {
    display: block;
    top: -25px;
  }
`;

export const NavButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #0a0a0a;
  cursor: pointer;
  padding: 8px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PageIndicator = styled.span`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 55px;
  text-align: center;
`;
