import React from "react";
import { Title, HighlightedText } from "./styles";

const FeatureTitle = ({ variant }) => (
  <Title variant={variant}>
    {variant === "scroll" ? (
      <>
        Create Personalized <HighlightedText>Email Campaigns</HighlightedText>
        <br /> For Free In Less Than A Minute
      </>
    ) : (
      <>
        Create Personalized Email Campaigns
        <br />
        For Free In Less Than A Minute
      </>
    )}
  </Title>
);

export default FeatureTitle;
