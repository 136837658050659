import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fffcf5;
  position: relative;
`;

export const HeroSection = styled.div`
  width: 100%;
  color: #ffffff;
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
