import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background: #fffcf5;
`;

export const BackToHomeBtn = styled.a`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -2%;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  display: flex;
  gap: 8px;
  margin: 56px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  img {
    width: 16px;
  }

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

export const HeroSection = styled.div`
  position: relative;
  width: 100%;
  padding: 120px 7% 80px;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-color: #391952;
    background-size: 20px 20px;
  }

  @media (max-width: 768px) {
    padding: 63px 24px 24px;
  }
`;

export const HeroContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  z-index: 1;
`;

export const HeroContent = styled.div`
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  gap: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const HeroDescription = styled.div`
  width: calc(50% - 24px);
  margin-top: 60px;
  margin-left: 24px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;

export const HeroTag = styled.div`
  width: fit-content;
  min-height: 28px;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -2%;
  color: #3e89ff;
  padding: 4px 8px;
  border-radius: 8px;
  background: #ebf3ff;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

export const HeroTitle = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 725px;
  color: #0a0a0a;

  font-family: Inter;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -3%;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const HeroSubtitle = styled.div`
  margin-top: 16px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -3%;
  color: #717784;
`;

export const HeroAuthor = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;

export const AuthorAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

export const AuthorName = styled.div`
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -2%;
  color: #0e121b;
`;

export const HeroInfo = styled.div`
  display: flex;
  margin-top: 24px;
  width: fit-content;
  align-items: center;
`;

export const HeroInfoSeparator = styled.div`
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 50%;
  margin: 0 8px;
`;

export const HeroLastUpd = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -2%;
  color: #717784;
`;

export const HeroReadTime = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -2%;
  color: #717784;
`;

export const HeroImage = styled.div`
  width: calc(50% - 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #d9d9d9;
  color: #a0a0a0;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 560px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`;

export const MainContainer = styled.div`
  background: #fffcf5;
  padding: 0 7%;

  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const MainSection = styled.div`
  display: flex;
  gap: 56px;
  margin: 0 auto;
  padding-bottom: 56px;
  max-width: 1200px;

  @media (max-width: 768px) {
    padding-bottom: 48px;
  }
`;

export const MainContent = styled.div`
  padding: 56px 0 0 0 !important;
  min-height: calc(100vh - 562px - 75px - 119px);
  width: calc(100% - 416px);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Navigation = styled.div`
  padding-top: 56px;
  width: 360px;
  position: sticky;
  top: 0;
  height: fit-content;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavigationTitle = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 16%;
  color: #717784;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const NavigationLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
`;

export const NavigationLink = styled.li`
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -2%;
  padding: 0 16px;
  cursor: pointer;
  color: #717784;

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: 600;
      color: #000;
      letter-spacing: -2%;
      border-left: 3px solid #612686;
    `}
`;

export const RelatedArticlesContainer = styled.div`
  background: #fffcf5;
  padding: 0 7%;
  padding-top: 56px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const RelatedArticlesWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

export const PromoContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: calc(1408px + 16px * 2);

  > div {
    margin: 0 16px;
    width: calc(100% - 16px * 2);

    @media (max-width: 768px) {
      margin: 40px 24px 24px;
      width: calc(100% - 24px * 2);
    }
  }
`;
