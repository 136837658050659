import React from "react";

import {
  Container,
  Title,
  ImageContainer,
  ImageContent,
  ImageWrapper,
  Image,
  StepNumber,
} from "./styles";

const StepItem = ({ step }) => {
  return (
    <Container>
      <StepNumber>{step.id}</StepNumber>
      <Title
        style={{
          maxWidth: step.titleMaxWidth,
        }}
      >
        {step.title}
      </Title>
      <ImageContainer>
        <ImageContent>
          <ImageWrapper>
            <Image src={step.imageSrc} alt={step.title} />
          </ImageWrapper>
        </ImageContent>
      </ImageContainer>
    </Container>
  );
};

export default StepItem;
