import { useRef } from "react";

export function useSwiperNavigation(handleFeatureClick) {
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    // Convert slider index to feature ID (adding 1 because IDs start from 1)
    const featureId = swiper.activeIndex + 1;

    handleFeatureClick(featureId);
  };

  const slideNext = () => swiperRef.current?.swiper?.slideNext();
  const slidePrev = () => swiperRef.current?.swiper?.slidePrev();

  return {
    swiperRef,
    handleSlideChange,
    slideNext,
    slidePrev,
  };
}
