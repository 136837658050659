import React from "react";

import Hero from "./Hero";
import Footer from "./Footer";
import Header from "../components/Header";
import Features from "./Features";
import StaticPromoSection from "../../../components/StaticPromoSection";

import { Root, HeroSection, Main, StaticPromoSectionContainer } from "./styles";

const MarketingPowerLanding2 = ({ landingInfo, onSubscribe }) => {
  return (
    <Root>
      <Header onSubscribe={onSubscribe} />
      <HeroSection>
        <Hero landingId={landingInfo.id} onSubscribe={onSubscribe} />
      </HeroSection>
      <Main>
        <Features />
        <StaticPromoSectionContainer>
          <StaticPromoSection
            landingId={landingInfo.id}
            onSubscribe={onSubscribe}
          />
        </StaticPromoSectionContainer>
      </Main>
      <Footer />
    </Root>
  );
};

export default MarketingPowerLanding2;
