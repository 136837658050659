import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  max-width: 678px;
  margin: 32px auto 0;

  @media (max-width: 767px) {
    padding: 0 9px;
  }
`;

export const Input = styled.input`
  padding: 3px 16px 3px 44px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #ffffff1f !important;
  background-color: #ffffff1a !important;
  color: #ffffff80;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 23px;
  }

  outline: none !important;
  box-shadow: 0px 1px 2px 0px #0d0d120a, 0px 1px 3px 0px #0d0d120d !important;
  backdrop-filter: blur(12px);

  &::placeholder {
    color: #ffffff80;
  }

  &:focus {
  }
`;

export const SearchIcon = styled.img.attrs({
  src: "/images/search-02.svg",
  alt: "Search",
})`
  position: absolute;
  top: calc((100% - 20px) / 2);
  left: 15px;
  width: 20px;
  height: 20px;

  @media (max-width: 767px) {
    left: calc(15px + 9px);
  }
`;
