import React, { useEffect, useState } from "react";
import {
  AccordionItem,
  AccordionHeader,
  AccordionTitle,
  AccordionIcon,
  AccordionContent,
} from "./styles";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [title]);

  return (
    <AccordionItem>
      <AccordionHeader $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon $isOpen={isOpen} />
      </AccordionHeader>
      <AccordionContent
        $isOpen={isOpen}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </AccordionItem>
  );
};

export default Accordion;
