import React from "react";

import StepItem from "./Step";

import { Container } from "./styles";

const STEPS = [
  {
    id: 1,
    title: "Install Reach for free from Google Chrome Store",
    imageSrc: "/images/landing-features/marketing-power-4/step-1.png",
    titleMaxWidth: "501px",
  },
  {
    id: 2,
    title: "Connect Reach to your Hubspot Account",
    imageSrc: "/images/landing-features/marketing-power-4/step-2.png",
    titleMaxWidth: "501px",
  },
  {
    id: 3,
    title: "Select your Contacts from Hubspot",
    imageSrc: "/images/landing-features/marketing-power-4/step-3.png",
    titleMaxWidth: "501px",
  },
  {
    id: 4,
    title:
      "Write Your campaign Goals and Reach Personalizes Every Email Based on Your Contact Data",
    imageSrc: "/images/landing-features/marketing-power-4/step-4.png",
    titleMaxWidth: "810px",
  },
  {
    id: 5,
    title: "Hit Send and Start Seeing Higher Engagements",
    imageSrc: "/images/landing-features/marketing-power-4/step-5.png",
    titleMaxWidth: "501px",
  },
];

const Steps = () => {
  return (
    <Container>
      {STEPS.map((step) => (
        <StepItem key={step.id} step={step} />
      ))}
    </Container>
  );
};

export default Steps;
