import styled from "styled-components";

export const Container = styled.div`
  border-radius: 64px;
  max-width: 1058px;
  max-height: 580px;
  aspect-ratio: 1058 / 580;
  width: 100%;
  border: 3px solid rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: 0px 4px 4px 0px #00000040;
  /* Fallback for browsers that don't support backdrop-filter */
  background-color: rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    border-radius: 20px;
    max-width: none;
    max-height: none;
    height: auto;
    aspect-ratio: auto;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    box-shadow: 0px 1.24px 1.24px 0px #00000040;
  }
`;

export const Content = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
`;
