import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 16px 32px -12px #0e121b1a;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Content = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ $noImage }) =>
    $noImage
      ? css`
          width: 100%;
          height: 296px;
          border-radius: 12px;
          background-color: #cccccc2b;
          position: relative;

          &::before {
            content: attr(data-header);
            position: absolute;
            top: 25px;
            left: 25px;
            width: calc(100% - 25px * 2);
            height: calc(100% - 25px * 2);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: ${({ theme }) => theme.fonts.interPrimary};
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.03em;
            color: #717784;
          }
        `
      : ""}

  img {
    width: 100%;
    height: 296px;
    object-fit: cover;
    border-radius: 12px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Tag = styled.div`
  padding: 4px 9px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: #ebf3ff;
  color: #3e89ff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
`;

export const Title = styled.div`
  margin-top: 16px;
  width: fit-content;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #0e121b;

  @media (max-width: 767px) {
    margin-top: 8px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
`;

export const SubTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #717784;
`;

export const Footer = styled.div`
  margin-top: auto;
  padding: 2px 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #717784;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ViewGuide = styled.div`
  padding-right: 31px;
  background-image: url("/images/arrow-up-right.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center right;
  color: #612686;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: 0%;
  text-decoration-thickness: 0%;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Rating = styled.div`
  padding-left: 29px;
  background-image: url("/images/star-rounded.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center left;
  color: #717784;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -2%;
  letter-spacing: -0.02em;
`;
