import React from "react";
import {
  Root,
  Content,
  Title,
  Highlight,
  Subtitle,
  VideoContainer,
} from "./styles";
import VideoPlayer from "../VideoPlayer";
import Rating from "../../components/Rating";
import EmailSubscribeForm from "../../components/EmailSubscribeForm";

const Hero = ({ landingId, onSubscribe }) => {
  return (
    <Root>
      <Content>
        <Title>
          Achieve Industry Defying
          <br />
          <Highlight>Email Engagement Rates with $0</Highlight>
        </Title>
        <Subtitle>
          All you need is Reach from Salesstream.ai + Free version of Hubspot
        </Subtitle>

        <EmailSubscribeForm
          landingId={landingId}
          onSuccess={onSubscribe}
          buttonText="Try Reach"
        />

        <Rating />
      </Content>

      <VideoContainer>
        <VideoPlayer videoId="IUqEC_Vb3qk" />
      </VideoContainer>
    </Root>
  );
};

export default Hero;
