/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "lenis/dist/lenis.css";

import Benefits from "./Benefits";
import Categories from "./Categories";
import PromoSection from "./PromoSection";
import Footer from "../Resources/Footer";
import {
  BlogsWrapper,
  MainContent,
  ArticleGrid,
  BenefitsContainer,
  LoaderContainer,
  Loader,
  EmptyData,
  ArticlesWrapper,
  ArticleGridWrapper,
  PromoSectionWrapper,
  LoadMoreBtn,
} from "./styles";
import { AppContext } from "../../contexts/AppContext";
import { getPosts } from "../../services/blog";
import { uniq } from "lodash";
import ArticleCard from "./ArticleCard";
import Search from "./Search";
import { waitForElement } from "../../utils/utils";

const LIMIT = 12;

const Blogs = () => {
  const context = useContext(AppContext);
  let { lenis } = context;
  const { isQaEnv } = context;
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [newestBlog, setNewestBlog] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(location.state?.tag || null);
  const [searchString, setSearchString] = useState("");
  const [curDate, setCurDate] = useState(new Date().toISOString());

  const fetchBlogs = async (params) => {
    const data = {
      newest: true,
      curDate,
      limit: LIMIT,
      offset: params?.clearAfterSearch ? 0 : blogs.length,
    };

    const isNewSearch = !data.offset;

    if (isNewSearch) {
      data.curDate = new Date().toISOString();
    }

    [
      ["tag", params?.tag ?? selectedTag],
      ["search", (params?.search ?? searchString).trim()],
    ].forEach(([k, v]) => {
      if (v) {
        data[k] = v;
      }
    });

    if (isNewSearch && !params?.clearAfterSearch) {
      setIsLoading(true);
    }
    setIsLoadingMore(!isNewSearch);

    const result = await getPosts(data, isQaEnv);

    setIsLoadingStart(false);
    setIsLoading(false);
    setIsLoadingMore(false);

    const resPosts = result.posts;

    if (!result?.success || !resPosts) {
      setHasMore(false);
      return;
    }

    const isAll = !data.tag && !data.search && isNewSearch;

    if (isAll || result.tags) {
      setTags(
        uniq(result.tags || resPosts.map((p) => p.tag))
          .filter(Boolean)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
      );
    }

    if (isAll || !newestBlog) {
      setNewestBlog(resPosts[0]);
    }

    const blogIds = blogs.map((b) => b.id);
    const newList = isNewSearch
      ? resPosts
      : [...blogs, ...resPosts.filter((p) => !blogIds.includes(p.id))];
    setBlogs(newList);

    setHasMore(result.meta?.hasMore || false);

    setCurDate(result.meta?.curDate || data.curDate);

    return newList;
  };

  const handleSelectTag = (val) => {
    setSelectedTag(val);
    fetchBlogs({ clearAfterSearch: true, tag: val ?? "" });
  };

  const handleSearch = (str) => {
    setSearchString(str);
    fetchBlogs({ clearAfterSearch: true, search: str ?? "" });
  };

  // We do it because we get content which created\edited by React Quill lib.
  const injectLink = () => {
    const link = document.createElement("link");

    link.rel = "stylesheet";
    link.href =
      "https://cdnjs.cloudflare.com/ajax/libs/quill/2.0.2/quill.snow.css";
    link.id = "quill-style";
    document.head.appendChild(link);

    return link;
  };

  useEffect(() => {
    window.history.replaceState({}, "");

    window.scrollTo({ top: 0, behavior: "smooth" });

    fetchBlogs();

    const link = injectLink();

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  if (isLoadingStart) {
    return (
      <LoaderContainer>
        <Loader>
          <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
        </Loader>
      </LoaderContainer>
    );
  }

  const handleLoadMore = async () => {
    const newList = await fetchBlogs();

    if (newList?.length) {
      try {
        await waitForElement(`blog-${newList.at(-1).id}`);
      } catch {}

      await new Promise((resolve) => setTimeout(resolve, 500));

      try {
        lenis.resize();
      } catch {}
    }
  };

  const handleOpenBlog = (blog) => {
    navigate(`/blog/${blog.link}`);
  };

  const renderCard = (blog, index, order) => {
    return (
      <ArticleCard
        key={index}
        order={order}
        data={blog}
        onClick={() => handleOpenBlog(blog)}
        openBlogsByTag={() => handleSelectTag(blog.tag)}
      />
    );
  };

  const gridTop1EndIdx = 3;
  const gridTop2EndIdx = 6;
  const gridTop1 = blogs.slice(0, gridTop1EndIdx);
  const gridTop2 = blogs.slice(gridTop1EndIdx, gridTop2EndIdx);
  const gridBottom = blogs.slice(gridTop2EndIdx);

  return (
    <BlogsWrapper>
      {isLoading && (
        <LoaderContainer $noBgAndFixed={true}>
          <Loader>
            <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
          </Loader>
        </LoaderContainer>
      )}

      <BenefitsContainer>
        <Benefits
          data={newestBlog}
          onClick={() => handleOpenBlog(newestBlog)}
          openBlogsByTag={() => handleSelectTag(newestBlog.tag)}
        />
      </BenefitsContainer>

      <MainContent>
        <Search onSearch={handleSearch} />

        <Categories
          categories={tags}
          activeCategory={selectedTag}
          onSelect={handleSelectTag}
        />

        <ArticlesWrapper>
          {!blogs.length && (
            <ArticleGridWrapper>
              <EmptyData>No blogs to read</EmptyData>
            </ArticleGridWrapper>
          )}

          {!!gridTop1.length && (
            <ArticleGridWrapper>
              <ArticleGrid $top={true}>
                {gridTop1.map((blog, index) =>
                  renderCard(blog, index, index + 1),
                )}
              </ArticleGrid>
            </ArticleGridWrapper>
          )}

          {!!gridTop2.length && (
            <ArticleGridWrapper>
              <ArticleGrid>{gridTop2.map(renderCard)}</ArticleGrid>
            </ArticleGridWrapper>
          )}

          <PromoSectionWrapper>
            <PromoSection />
          </PromoSectionWrapper>

          {!!gridBottom.length && (
            <ArticleGridWrapper>
              <ArticleGrid>{gridBottom.map(renderCard)}</ArticleGrid>
            </ArticleGridWrapper>
          )}

          {hasMore && (
            <LoadMoreBtn
              $disabled={isLoadingMore || isLoading}
              onClick={handleLoadMore}
            >
              Load More Articles
              <img src="/images/arrow-down.svg" alt="" />
            </LoadMoreBtn>
          )}
        </ArticlesWrapper>
      </MainContent>

      <Footer />
    </BlogsWrapper>
  );
};

export default Blogs;
