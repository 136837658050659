import React from "react";
import { Root, ContentContainer, Badge, Subtitle } from "./styles";
import FeatureTitle from "./title";
import ScrollVariant from "./scrollSteps";
import DefaultVariant from "./defaultSteps";

const stepsInfo = {
  default: {
    imagesPath: "/images/landing-features/killing-business",
    steps: [
      {
        title: "Write your campaign goals",
        description: [
          "Tell Reach what you want to achieve from this campaign",
          "Share your campaign details with Reach",
          "Or use Reach's prompt generator to write campaign details by answering a few questions",
        ],
      },
      {
        title: "Choose your contacts from a spreadsheet or a CRM like Hubspot",
        description: [
          "Select your contacts from your spreadsheet or CRM",
          "No uploads required",
          "No templates required",
        ],
      },
      {
        title: "Review the emails Generated by Reach from Salesstream.ai",
        description: [
          "Select the tone for email",
          "Select the numbers of followups required",
          "Now review the emails generated by Reach. Make edits if required",
        ],
      },
      {
        title: "Hit Send and start seeing the results",
        description: [
          "Launch your campaign",
          "Witness your open rates & engagement rates skyrocket",
          "You can view your email replies in your inbox and in Reach dashboard as well",
        ],
      },
    ],
  },
  scroll: {
    imagesPath: "/images/landing-features/marketing-power",
    steps: [
      {
        title: "Install & Launch in One Click",
        description: [
          "Install Reach for free from Google Chrome Store",
          "Pin the Reach Extension to your taskbar",
        ],
      },
      {
        title: "Connect Reach from Salesstream.ai to Hubspot",
        description: [
          "Provide Reach access to your Hubspot account",
          "Connect the app in a single click",
        ],
      },
      {
        title: "Create Your Reach Account",
        description: [
          "Signup using your email",
          "Now your Reach account is ready for action",
        ],
      },
      {
        title: "Start Creating Your Email Campaign",
        description: [
          "Write your goals and Launch your campaign",
          "Now Witness your open rates & engagement rates skyrocket",
        ],
      },
    ],
  },
};

const Features = ({ variant = "default", stepsVariant }) => {
  const isScrollVariant = variant === "scroll";

  const variants = {
    scroll: ScrollVariant,
    default: DefaultVariant,
  };

  const CurrentVariant = variants[variant];

  return (
    <Root>
      <ContentContainer variant={variant}>
        {isScrollVariant && <Badge>How it Works</Badge>}
        <FeatureTitle variant={variant} />
        {isScrollVariant && (
          <Subtitle>
            Send Personalized Email Campaigns That Hit the Inbox, Not the Spam
            Folder
          </Subtitle>
        )}
        <CurrentVariant
          stepsInfo={stepsInfo[stepsVariant || variant]}
          variant={variant}
        />
      </ContentContainer>
    </Root>
  );
};

export default Features;
