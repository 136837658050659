import React, { createContext, useContext } from "react";

import { useFeaturedPost } from "../hooks";
import { AppContext } from "../../../contexts/AppContext";

const MainHeaderContext = createContext();

export const MainHeaderProvider = ({ children }) => {
  const { isQaEnv } = useContext(AppContext);
  const { featuredPost } = useFeaturedPost(isQaEnv);

  return (
    <MainHeaderContext.Provider value={{ featuredPost }}>
      {children}
    </MainHeaderContext.Provider>
  );
};

export const useMainHeader = () => {
  const context = useContext(MainHeaderContext);

  if (!context) {
    throw new Error("useMainHeader must be used within MainHeaderProvider");
  }

  return context;
};
