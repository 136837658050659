import styled, { css } from "styled-components";

export const BlogsWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  position: relative;
  background: linear-gradient(
    263.83deg,
    #fef8e966 61.82%,
    rgba(246, 247, 252, 0.4) 88.44%
  );
  background-color: #fffcf5;
  display: flex;
  flex-direction: column;
`;

export const BenefitsContainer = styled.div`
  background-color: #391952;
  position: relative;
  width: 100%;
  padding: 168px 0 80px;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 24px;
  }
`;

export const MainContent = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 80px 0 112px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  row-gap: 56px;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (max-width: 767px) {
    padding: 24px 15px;
    row-gap: 48px;
  }
`;

export const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 56px;

  @media (max-width: 767px) {
    padding: 0 9px;
    row-gap: 48px;
  }
`;

export const ArticleGridWrapper = styled.div``;

export const ArticleGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  gap: 24px;

  ${({ $top }) =>
    $top
      ? css`
          grid-template-columns: repeat(2, 1fr);
        `
      : css`
          grid-template-columns: repeat(3, 1fr);
        `}

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const PromoSectionWrapper = styled.div``;

export const LoaderContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $noBgAndFixed }) =>
    $noBgAndFixed
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 99999;
        `
      : css`
          background: linear-gradient(180deg, #391952 120px, transparent 120px);
          position: relative;
          &::before {
            content: "";
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: radial-gradient(
              rgba(204, 204, 204, 0.1) 1.5px,
              transparent 0
            );
            background-size: 20px 20px;
          }

          @media (max-width: 977px) {
            background: linear-gradient(180deg, #391952 84px, transparent 84px);
          }
        `}
`;

export const Loader = styled.div`
  @keyframes wait-animation-s {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: wait-animation-s -1ms 800ms ease-in;

  @keyframes slide {
    0% {
      transform: translate(0, 0);
    }
    2% {
      transform: translate(${({ $widthEl }) => $widthEl}px, 0);
    }
    12.5% {
      transform: translate(${({ $widthEl }) => $widthEl}px, 0);
    }
    15.5% {
      transform: translate(${({ $widthEl }) => 2 * $widthEl}px, 0);
    }
    25% {
      transform: translate(${({ $widthEl }) => 2 * $widthEl}px, 0);
    }
    27% {
      transform: translate(
        ${({ $widthEl }) => 2 * $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    37.5% {
      transform: translate(
        ${({ $widthEl }) => 2 * $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    39.5% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    50% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    52% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => 2 * $widthEl}px
      );
    }
    62.5% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => 2 * $widthEl}px
      );
    }
    64.5% {
      transform: translate(0, ${({ $widthEl }) => 2 * $widthEl}px);
    }
    75% {
      transform: translate(0, ${({ $widthEl }) => 2 * $widthEl}px);
    }
    77% {
      transform: translate(0, ${({ $widthEl }) => $widthEl}px);
    }
    87.5% {
      transform: translate(0, ${({ $widthEl }) => $widthEl}px);
    }
    89.5% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes gradient1 {
    from {
      stop-color: #4383b8;
    }
    50% {
      stop-color: #8013b9;
    }
    to {
      stop-color: #4383b8;
    }
  }
  @keyframes gradient2 {
    from {
      stop-color: #4aa06c;
    }
    50% {
      stop-color: #b22358;
    }
    to {
      stop-color: #4aa06c;
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${({ $inBtn }) =>
    $inBtn
      ? css`
          position: absolute;
          top: -25px;
          left: calc(50% - 10px);
        `
      : ""}
`;

export const EmptyData = styled.p`
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.04em;
  opacity: 0.75;

  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 43px;
    text-align: center;
  }
`;

export const LoadMoreBtn = styled.div`
  margin: 0 auto;
  padding: 17px 20px 16px;
  width: fit-content;
  border-radius: 12px;
  background: #612686;
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  column-gap: 8px;
  outline: none;
  cursor: pointer;
  position: relative;

  ${({ $disabled }) =>
    $disabled
      ? css`
          pointer-events: none;
          opacity: 0.65;
        `
      : ""}

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.9;
  }
`;
