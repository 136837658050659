import React from "react";
import MarketingLanding from "../components/MarketingLanding";

const MarketingPowerLanding = ({
  landingInfo,
  onSubscribe,
  isExtensionInstalled,
}) => {
  return (
    <MarketingLanding
      landingInfo={landingInfo}
      onSubscribe={onSubscribe}
      isExtensionInstalled={isExtensionInstalled}
      title={
        <>
          Frustrated with HubSpot’s <br />
          <span>Paid Plans?</span>
          -Here’s the Fix!
        </>
      }
    />
  );
};

export default MarketingPowerLanding;
