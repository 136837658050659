import { fetchPostByLink, fetchPosts, fetchFeaturedPost } from "./queries";

const getPostByLink = async (data, isQaEnv) => {
  const result = await fetchPostByLink(data, isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

const getPosts = async (data, isQaEnv) => {
  const result = await fetchPosts(data, isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

const getFeaturedPost = async (isQaEnv) => {
  const result = await fetchFeaturedPost(isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

export { getPostByLink, getPosts, getFeaturedPost };
