import React, { useState, useRef } from "react";
import { useCategoriesSticky } from "./hooks/useCategoriesSticky";
import {
  CategoriesContainer,
  CategoriesDropdownButton,
  CategoriesDropdownContainer,
  CategoriesDropdownTitle,
  CategoryButtons,
  CategoryButton,
} from "./styles";

const Categories = ({ categories, activeCategory, onCategoryChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const categoriesRef = useRef(null);

  useCategoriesSticky(categoriesRef);

  const handleChangeCategory = (cat) => {
    onCategoryChange(cat);
    setIsOpen(false);
  };

  return (
    <>
      <CategoriesDropdownContainer>
        <CategoriesDropdownTitle>Categories</CategoriesDropdownTitle>
        <CategoriesDropdownButton
          $isActive={true}
          $isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          {activeCategory}
        </CategoriesDropdownButton>
      </CategoriesDropdownContainer>

      <CategoriesContainer $isOpen={isOpen} ref={categoriesRef}>
        <CategoryButtons>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              $isActive={activeCategory === category}
              onClick={() => handleChangeCategory(category)}
            >
              {category}
            </CategoryButton>
          ))}
        </CategoryButtons>
      </CategoriesContainer>
    </>
  );
};

export default Categories;
