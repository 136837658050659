import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Item = styled.li`
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (min-width: 978px) {
    height: 100%;
  }

  @media (max-width: 977px) {
    margin: 5px 0;
  }
`;

export const Name = styled.span`
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;

  ${({ $hasSubmenu, $isSubmenuVisible }) =>
    $hasSubmenu &&
    css`
      position: relative;
      padding-right: 20px;
      &::after {
        content: "";
        background-image: url("/images/chevron-down-icon-white.svg");
        background-repeat: no-repeat;
        background-size: 16px;
        position: absolute;
        top: 1px;
        right: 0;
        width: 16px;
        height: 16px;
        transition: transform 0.3s ease;

        .isSticky & {
          background-image: url("/images/chevron-down-icon-black.svg");

          @media (max-width: 977px) {
            background-image: url("/images/chevron-down-icon-white.svg");
          }
        }

        ${$isSubmenuVisible &&
        css`
          transform: rotate(180deg);
        `}
      }
    `}

  .isSticky & {
    color: #000;

    @media (max-width: 977px) {
      color: #fff;
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  transition: background-color 0.3s ease;
  padding: 4px 6px;

  ${({ $isActive, $isSubmenuVisible }) =>
    ($isActive || $isSubmenuVisible) &&
    css`
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 8px;

      .isSticky & {
        background-color: rgba(0, 0, 0, 0.15);
      }

      @media (max-width: 977px) {
        .isSticky & {
          background-color: rgba(255, 255, 255, 0.15);
        }
      }
    `}
`;

export const StyledMenuItem = styled.span`
  transition: background-color 0.3s ease;
  padding: 4px 6px;

  ${({ $isActive, $isSubmenuVisible }) =>
    ($isActive || $isSubmenuVisible) &&
    css`
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 8px;

      .isSticky & {
        background-color: rgba(0, 0, 0, 0.15);
      }

      @media (max-width: 977px) {
        .isSticky & {
          background-color: rgba(255, 255, 255, 0.15);
        }
      }
    `}
`;

export const SubmenuLink = styled.a`
  margin-top: 32px;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  color: #000;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-right: 24px;

  &:hover {
    text-decoration: underline;

    &::after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url("/images/arrow-right-black.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
