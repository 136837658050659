import React from "react";
import { Container, Link } from "./styles";

const handleCopyLink = () => {
  navigator.clipboard.writeText(window.location.href);
};

const handleShare = (platform) => {
  const url = encodeURIComponent(window.location.href);
  let shareUrl;
  switch (platform) {
    case "facebook":
      shareUrl = `https://www.facebook.com/dialog/share?href=${url}`;
      break;
    case "twitter":
      shareUrl = `https://twitter.com/intent/tweet?url=${url}`;
      break;
    case "linkedin":
      shareUrl = `https://www.linkedin.com/shareArticle?url=${url}`;
      break;
    default:
      return;
  }

  window.open(shareUrl, "_blank");
};

const ShareLinks = () => {
  return (
    <Container>
      <Link onClick={handleCopyLink}>
        <img src="/images/share-icons/Button.svg" alt="link" />
      </Link>
      <Link onClick={() => handleShare("facebook")}>
        <img src="/images/share-icons/Facebook.svg" alt="facebook" />
      </Link>
      <Link onClick={() => handleShare("twitter")}>
        <img src="/images/share-icons/Twitter.svg" alt="twitter" />
      </Link>
      <Link onClick={() => handleShare("linkedin")}>
        <img src="/images/share-icons/LinkedIn.svg" alt="linkedin" />
      </Link>
    </Container>
  );
};

export default ShareLinks;
