import React from "react";
import {
  ArticleCardContainer,
  ArticleCard,
  ArticleContent,
  ArticleImageContainer,
  ArticleSubTitle,
  ArticleTag,
  ArticleTitle,
  Author,
  Meta,
  MetaSeparator,
  Container,
  Description,
  Title,
} from "./styles";
import { calcReadTime, displayDateShort } from "../../../utils/utils";

function Benefits({ data, onClick, openBlogsByTag }) {
  return (
    <Container>
      <Title>Blog</Title>
      <Description>Latest news and updates from Reach</Description>
      {!!data && (
        <ArticleCardContainer>
          <ArticleCard>
            <ArticleContent>
              <ArticleTag onClick={openBlogsByTag}>{data.tag}</ArticleTag>
              <ArticleTitle onClick={onClick}>{data.title}</ArticleTitle>
              <ArticleSubTitle>{data.subTitle}</ArticleSubTitle>
              <Meta>
                {displayDateShort(data.publishDate)} <MetaSeparator />{" "}
                {calcReadTime(data.text)} min read
              </Meta>
              <Author>
                <img src={data.profilePicture} alt="Author Avatar" />
                <span>{data.writer}</span>
              </Author>
            </ArticleContent>
            <ArticleImageContainer>
              <img src={data.heroImage} alt="Illustration" />
            </ArticleImageContainer>
          </ArticleCard>
        </ArticleCardContainer>
      )}
    </Container>
  );
}

export default Benefits;
