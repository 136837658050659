import React from "react";
import {
  Root,
  Content,
  Title,
  Highlight,
  Subtitle,
  VideoContainer,
} from "./styles";
import VideoPlayer from "../VideoPlayer";
import Rating from "../../components/Rating";
import EmailSubscribeForm from "../../components/EmailSubscribeForm";

const Hero = ({ landingId, onSubscribe }) => {
  return (
    <Root>
      <Content>
        <Title>
          Templated Email’s are
          <br />
          <Highlight>Killing your Business</Highlight>
        </Title>
        <Subtitle>
          Instead send personalized email campaigns right out of your
          spreadsheet
        </Subtitle>

        <EmailSubscribeForm landingId={landingId} onSuccess={onSubscribe} />
        <Rating />
      </Content>

      <VideoContainer>
        <VideoPlayer videoId="IUqEC_Vb3qk" />
      </VideoContainer>
    </Root>
  );
};

export default Hero;
