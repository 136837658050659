import { createContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { NAVIGATION_TAB_LIST } from "../utils/constants";
import { shouldShowNavbar } from "../utils/utils";

export const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("selectedTab")) || NAVIGATION_TAB_LIST[0]
    );
  });

  const isNavbarVisible = shouldShowNavbar(location.pathname);
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    localStorage.setItem("selectedTab", JSON.stringify(selectedTab));
  }, [selectedTab]);

  const determineActiveTabByUrl = useCallback(() => {
    const isResourcesSection = ["/resources", "/blogs", "/guides"].some(
      (path) => location.pathname.startsWith(path),
    );

    if (isResourcesSection) {
      const resourcesTab = NAVIGATION_TAB_LIST.find(
        (tab) => tab.key === "resources",
      );
      if (resourcesTab) {
        setSelectedTab(resourcesTab);
      }
      return;
    }

    const activeTab = NAVIGATION_TAB_LIST.find(
      (tab) => tab.link === location.pathname,
    );
    if (activeTab) {
      setSelectedTab(activeTab);
    }
  }, [location.pathname]);

  const findActiveTabOnScroll = useCallback(() => {
    let newActiveTab = null;

    NAVIGATION_TAB_LIST.forEach((tab) => {
      const section = document.getElementById(tab.key);
      if (!section) return;

      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2) {
        newActiveTab = tab;
      }
    });

    if (newActiveTab) {
      setSelectedTab(newActiveTab);
    }
  }, []);

  useEffect(() => {
    determineActiveTabByUrl();
  }, [determineActiveTabByUrl]);

  useEffect(() => {
    if (!isNavbarVisible || !isHomePage) return;

    findActiveTabOnScroll();
    window.addEventListener("scroll", findActiveTabOnScroll);

    return () => {
      window.removeEventListener("scroll", findActiveTabOnScroll);
    };
  }, [isNavbarVisible, isHomePage, findActiveTabOnScroll]);

  return (
    <NavigationContext.Provider
      value={{ selectedTab, setSelectedTab, isNavbarVisible }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
