import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 45px;
  overflow: hidden;
  background-color: #000000;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  img {
    width: 106px;
    height: 108px;
  }
`;

export const Video = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
