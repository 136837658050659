import React from "react";
import {
  Step,
  StepTitle,
  StepDescription,
  StepItem,
  StepImage,
  StepText,
} from "./styles";

const StepContent = ({ step, index, imagesPath, variant }) => (
  <Step key={index} number={index + 1} variant={variant}>
    <StepText variant={variant}>
      <StepTitle variant={variant} $number={index + 1}>
        {step.title}
      </StepTitle>
      <StepDescription variant={variant}>
        {step.description.map((item, index) => (
          <StepItem key={index} variant={variant}>
            {item}
          </StepItem>
        ))}
      </StepDescription>
    </StepText>
    <StepImage variant={variant}>
      <img src={`${imagesPath}/${index + 1}.png`} alt={step.title} />
    </StepImage>
  </Step>
);

export default StepContent;
