import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Benefits from "./Benefits";
import Categories from "./Categories";
import Accordion from "./Accordion";
import PromoSection from "../../components/PromoSection";
import Footer from "./Footer";
import {
  ResourcesWrapper,
  MainContent,
  AccordionGrid,
  AccordionColumn,
  BenefitsContainer,
  LoaderContainer,
  Loader,
  EmptyData,
  AccordionsWrapper,
  AccordionGridWrapper,
  PromoSectionWrapper,
} from "./styles";
import { AppContext } from "../../contexts/AppContext";
import { getMegaPage, getMegaPageData } from "../../services/megaPage";
import { debounce } from "lodash";

const ALL_CATEGORIES_LABEL = "All Categories";

const Resources = ({ isGuides }) => {
  const params = useParams();
  const linkPrm = params.link;
  const { isQaEnv } = useContext(AppContext);
  const hasFetchedMegaPage = useRef(null);

  const [megaPage, setMegaPage] = useState({});
  const [megaPageData, setMegaPageData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(ALL_CATEGORIES_LABEL);
  const [searchByWords, setSearchByWords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMegaPage = useCallback(async () => {
    if (hasFetchedMegaPage.current === linkPrm) {
      return;
    }

    hasFetchedMegaPage.current = linkPrm;

    setSearchByWords([]);
    setActiveCategory(ALL_CATEGORIES_LABEL);
    setIsLoading(true);
    const result = await getMegaPage(linkPrm, isQaEnv);

    if (!result.success) {
      setIsLoading(false);
      return;
    }

    setMegaPage(result.megaPage);
    setIsLoading(false);
  }, [linkPrm, isQaEnv]);

  const fetchMegaPageData = useCallback(async () => {
    if (!megaPage.id && isGuides) return;

    setSearchByWords([]);
    setActiveCategory(ALL_CATEGORIES_LABEL);
    setIsLoading(true);
    const result = await getMegaPageData(megaPage.id || undefined, isQaEnv);

    if (!result?.success) {
      setIsLoading(false);
      return;
    }

    setMegaPageData(result.megaPageData);

    const allCategories =
      result.megaPageData.length > 0
        ? [
            ALL_CATEGORIES_LABEL,
            ...new Set(result.megaPageData.map((item) => item.topic)),
          ]
        : [];

    setCategories(allCategories);
    setIsLoading(false);
  }, [megaPage.id, isQaEnv, isGuides]);

  useEffect(() => {
    if (isGuides) {
      fetchMegaPage();
    } else {
      fetchMegaPageData();
    }
  }, [fetchMegaPage, fetchMegaPageData, isGuides, linkPrm, isQaEnv]);

  useEffect(() => {
    if (isGuides && megaPage.id) {
      fetchMegaPageData();
    }
  }, [megaPage.id, fetchMegaPageData, isGuides]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChangeCategory = (cat) => {
    setActiveCategory(cat);
    setSearchByWords([]);
  };

  const handleSearch = (str) => {
    const words = str.toLowerCase().split(/\s/g).filter(Boolean);
    setSearchByWords(words);
    setActiveCategory(ALL_CATEGORIES_LABEL);
  };

  const handleChangeSearch = debounce((str) => {
    handleSearch(str);
  }, 500);

  const questions = megaPageData.filter(
    (q) =>
      (activeCategory === ALL_CATEGORIES_LABEL ||
        q.topic.toLowerCase() === activeCategory.toLowerCase()) &&
      (!searchByWords.length ||
        searchByWords.some(
          (word) =>
            q.answer.toLowerCase().includes(word) ||
            q.question.toLowerCase().includes(word),
        )),
  );

  // We do it because we get content which created\edited by React Quill lib.
  const injectLink = () => {
    const link = document.createElement("link");

    link.rel = "stylesheet";
    link.href =
      "https://cdnjs.cloudflare.com/ajax/libs/quill/2.0.2/quill.snow.css";
    link.id = "quill-style";
    document.head.appendChild(link);

    return link;
  };

  useEffect(() => {
    const link = injectLink();

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader>
          <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
        </Loader>
      </LoaderContainer>
    );
  }

  const questionsMiddleNum = Math.ceil(questions.length / 2);

  return (
    <ResourcesWrapper>
      <BenefitsContainer>
        <Benefits
          onChangeSearch={handleChangeSearch}
          onSearch={handleSearch}
          isResetSearch={!searchByWords.length}
          megaPage={megaPage}
        />
      </BenefitsContainer>

      {megaPageData.length === 0 ? (
        <EmptyData>No Data to show</EmptyData>
      ) : (
        <MainContent>
          <Categories
            categories={categories}
            activeCategory={activeCategory}
            onCategoryChange={handleChangeCategory}
          />
          <AccordionsWrapper>
            <AccordionGridWrapper>
              <AccordionGrid>
                <AccordionColumn>
                  {questions
                    .slice(0, questionsMiddleNum)
                    .map((question, index) => (
                      <Accordion
                        key={index}
                        title={question.question}
                        content={question.answer}
                      />
                    ))}
                </AccordionColumn>
              </AccordionGrid>
            </AccordionGridWrapper>
            <PromoSectionWrapper>
              <PromoSection forResource />
            </PromoSectionWrapper>
            <AccordionGridWrapper>
              <AccordionGrid>
                <AccordionColumn>
                  {questions
                    .slice(questionsMiddleNum)
                    .map((question, index) => (
                      <Accordion
                        key={index + questionsMiddleNum}
                        title={question.question}
                        content={question.answer}
                      />
                    ))}
                </AccordionColumn>
              </AccordionGrid>
            </AccordionGridWrapper>
          </AccordionsWrapper>
        </MainContent>
      )}
      <Footer />
    </ResourcesWrapper>
  );
};

export default Resources;
