import React from "react";

import Preview from "./Preview";
import Description from "./Description";

import { Container } from "./styles";

function Features() {
  return (
    <Container>
      <Preview />
      <Description />
    </Container>
  );
}

export default Features;
