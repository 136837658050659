import styled, { css } from "styled-components";

export const CategoriesContainer = styled.div`
  padding: 0;
  min-width: 260px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;
  transition: padding-top 0.2s ease-in-out;

  @media (min-width: 768px) {
    &.p-sticky {
      position: sticky;
      top: 0;
      padding-top: 120px;
      height: fit-content;
      width: 260px;
    }
  }

  @media (max-width: 767px) {
    min-width: 100%;
    max-width: 100%;
    padding: 0 9px;

    ${({ $isOpen }) =>
      $isOpen
        ? css``
        : css`
            display: none;
          `}
  }
`;

export const CategoryButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (max-width: 767px) {
    width: calc(100% - 9px * 2);
    padding: 11px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    background: #ffffff;
    box-shadow: 0px 16px 56px 0px #00000026;
    position: absolute;
    top: -40px;
    left: 9px;
    z-index: 1;
  }
`;

export const CategoryButton = styled.div`
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 15.22px;
  font-weight: 400;
  line-height: 22.82px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
  cursor: pointer;
  outline: none !important;
  position: relative;

  padding: 11px 12px;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  background: ${({ $isActive }) => ($isActive ? "#ffffff" : "transparent")};
  color: ${({ $isActive }) => ($isActive ? "#0D0D12" : "#737373")};
  border: ${({ $isActive }) =>
    `1px solid ${$isActive ? "#e5e5e5" : "transparent"}`};
  border-radius: 8px;
  box-shadow: none;

  &:hover {
    color: ${({ $isActive }) => ($isActive ? "#111827" : "#374151")};
  }

  @media (max-width: 767px) {
    width: 100%;
    ${({ $isOpen, $isActive }) =>
      $isOpen === true || $isOpen === false
        ? css``
        : css`
            padding: 12px;
            border: none;
            background: ${$isActive ? "#F6F6F6" : "transparent"};
            letter-spacing: ${$isActive ? "-0.02em" : "unset"};
          `}
  }
`;

export const CategoriesDropdownContainer = styled.div`
  margin-bottom: 48px;
  padding: 0 9px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CategoriesDropdownTitle = styled.div`
  padding: 12px;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
`;

export const CategoriesDropdownButton = styled(CategoryButton)`
  padding-right: 40px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  position: relative;

  &::after {
    content: "";
    background-image: url("/images/chevron-down-icon-black.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    position: absolute;
    top: calc((100% - 20px) / 2);
    right: 11px;
    width: 20px;
    height: 20px;
    transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
    transition: transform 0.2s ease;
  }
`;
