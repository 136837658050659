import React, { useState } from "react";
import { Form, Input, Button, Message } from "./styles";
import { addToWaitList } from "../../../../utils/utils";
import { CHROME_STORE_EXTENSION_URL } from "../../../../utils/constants";

const EmailSubscribeForm = ({
  buttonText = "Try Reach",
  isYellow = false,
  onSuccess,
  landingId,
  isExtensionInstalled = false,
}) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await addToWaitList(email, landingId);

    // Use the History API to add "/thank-you" to the URL
    window.history.pushState({}, "", `${window.location.pathname}/thank-you`);

    setEmail("");
    
    // Wait 500ms, then redirect to Chrome store
    setTimeout(() => {
      // Call onSuccess if provided, which triggers the Chrome store redirect
      if (onSuccess) {
        onSuccess(response);
      } else {
        // If no onSuccess callback is provided, redirect directly
        window.open(CHROME_STORE_EXTENSION_URL, "_blank");
      }
    }, 500);
  };

  const handleInstallClick = () => {
    // Use the History API to add "/thank-you" to the URL first
    window.history.pushState({}, "", `${window.location.pathname}/thank-you`);
    
    // Wait 500ms, then redirect to Chrome store
    setTimeout(() => {
      window.open(CHROME_STORE_EXTENSION_URL, "_blank");
    }, 500);
  };

  if (isExtensionInstalled) {
    return (
      <Button 
        onClick={handleInstallClick} 
        $isYellow={true}
        type="button"
      >
        Install now - it's Free!
      </Button>
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit} $marginBottom="16px">
        <Input
          type="email"
          placeholder={isYellow ? "Enter your email" : "Your Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          $isFlat={isYellow}
        />
        <Button type="submit" $isYellow={isYellow}>
          {buttonText}
        </Button>
      </Form>
      {isYellow && <Message>No credit card required!</Message>}
    </>
  );
};

export default EmailSubscribeForm;
