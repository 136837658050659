import React from "react";
import { Root, Title, Subtitle, IconsContainer, IconWrapper } from "./styles";

const Integrations = ({ variant }) => {
  return (
    <Root>
      <Title variant={variant}>
        {variant === "framed" ? (
          <>
            Seamless Integrations
            <br />
            at Your Fingertips
          </>
        ) : (
          "Seamless Integrations at your finger tips"
        )}
      </Title>
      {variant === "framed" && (
        <Subtitle>
          Reach from SalesStream.ai easily connects to your favorite tools
        </Subtitle>
      )}
      <IconsContainer variant={variant}>
        <IconWrapper variant={variant}>
          <img src="/images/bullhorn.png" alt="Bullhorn" />
        </IconWrapper>
        <IconWrapper variant={variant}>
          <img src="/images/hubspot.png" alt="Hubspot" />
        </IconWrapper>
        <IconWrapper variant={variant}>
          <img src="/images/excel.png" alt="Excel" />
        </IconWrapper>
        <IconWrapper variant={variant}>
          <img src="/images/google-sheets.png" alt="Google Sheets" />
        </IconWrapper>
      </IconsContainer>
    </Root>
  );
};

export default Integrations;
