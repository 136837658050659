import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;

export const Title = styled.h2`
  font-family: Inter;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -3%;
  text-align: center;
  color: #0a0a0a;
  margin-bottom: 96px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
