import React, { useState } from "react";
import PropTypes from "prop-types";
import { Root, Placeholder, PlayButton, Video } from "./styles";

const VideoPlayer = ({ videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <Root>
      {!isPlaying ? (
        <>
          <Placeholder>
            <img src="/images/video-placeholder.png" alt="Video preview" />
            <PlayButton onClick={handlePlay}>
              <img src="/images/play-button.svg" alt="Play video" />
            </PlayButton>
          </Placeholder>
        </>
      ) : (
        <Video>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
      )}
    </Root>
  );
};

VideoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default VideoPlayer;
