import { useEffect } from "react";

export const useCategoriesSticky = (categoriesRef) => {
  useEffect(() => {
    if (!categoriesRef?.current) return;

    const updateCategoriesOnScroll = () => {
      const rect = categoriesRef.current.getBoundingClientRect();

      if (rect.top <= 0) {
        categoriesRef.current.classList.add("p-sticky");
      } else {
        categoriesRef.current.classList.remove("p-sticky");
      }
    };

    updateCategoriesOnScroll();
    window.addEventListener("scroll", updateCategoriesOnScroll);

    return () => window.removeEventListener("scroll", updateCategoriesOnScroll);
  }, [categoriesRef]);
};
