import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

export const Container = styled.div`
  padding: 8px 0;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Title = styled.h2`
  font-family: Neue Montreal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25em;
  color: #0a0a0a;
  order: 1;

  @media (max-width: 768px) {
    order: 1;
    margin: 0 auto;
  }
`;

export const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  width: 100%;
  order: 3;

  @media (max-width: 768px) {
    order: 2;
    width: 100%;
  }
`;

export const SwiperStyled = styled(Swiper)`
  width: 100%;
  padding: 30px 0 50px 0;

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

export const FakeSlide = styled(SwiperSlide)`
  opacity: 0;
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  position: absolute;
  top: 15px;
  right: 0;

  @media (max-width: 768px) {
    order: 3;
    position: static;
    margin: 0 auto;
  }
`;

export const NavButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #0a0a0a;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PageIndicator = styled.span`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 55px;
  text-align: center;
`;
