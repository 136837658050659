import React, { useContext, useEffect, useState, useCallback } from "react";
import Post from "./Post";
import { useParams } from "react-router-dom";
import { getPostByLink } from "../../services/blog";
import { AppContext } from "../../contexts/AppContext";
import { Loader, LoaderContainer } from "./styles";

const Blog = () => {
  const params = useParams();
  const { isQaEnv } = useContext(AppContext);

  const [postInfo, setPostInfo] = useState({ title: "", content: "" });
  const [isLoading, setIsLoading] = useState(true);

  const getBlogInfo = useCallback(async () => {
    const link = params.link;

    setIsLoading(true);
    const result = await getPostByLink(link, isQaEnv);

    if (!result.success) {
      setPostInfo({
        title: "Not Found",
      });
      setIsLoading(false);
      return;
    }

    const postInfo = result.post;
    setPostInfo(postInfo);
    setIsLoading(false);
  }, [params.link, isQaEnv]);

  useEffect(() => {
    getBlogInfo();
  }, [getBlogInfo]);

  // We do it because we get content which created\edited by React Quill lib.
  const injectLink = () => {
    const link = document.createElement("link");

    link.rel = "stylesheet";
    link.href =
      "https://cdnjs.cloudflare.com/ajax/libs/quill/2.0.2/quill.snow.css";
    link.id = "quill-style";
    document.head.appendChild(link);

    return link;
  };

  useEffect(() => {
    const link = injectLink();

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader>
          <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
        </Loader>
      </LoaderContainer>
    );
  }

  return <Post postInfo={postInfo} />;
};

export default Blog;
