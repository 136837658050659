import React from "react";

import FeaturedBlogPost from "./FeaturedBlogPost";
import { useMainHeader } from "../../context/MainHeaderContext";

import {
  SubmenuContainer,
  SubmenuContentContainer,
  SubmenuDescription,
  SubmenuLink,
  SubmenuLinkContent,
  SubmenuMainInfo,
  SubmenuTabDescription,
  SubmenuTabIcon,
  SubmenuTabItem,
  SubmenuTabLink,
  SubmenuTabList,
  SubmenuTabTitle,
  SubmenuTitle,
  SubmenuWrapper,
} from "./styles";

function DesktopSubMenu({
  data,
  handleSubmenuLinkClick,
  isActive,
  isSubmenuVisible,
}) {
  const { featuredPost } = useMainHeader();
  const { key, title, description, link, isAnchor, tabs } = data;
  const isResourcesSubmenu = key === "resources";
  const shouldRenderFeaturedPost =
    featuredPost && isResourcesSubmenu && (isActive || isSubmenuVisible);

  return (
    <SubmenuWrapper $isSubmenuVisible={isSubmenuVisible}>
      <SubmenuContainer>
        <SubmenuContentContainer>
          <SubmenuMainInfo>
            <SubmenuTitle>{title}</SubmenuTitle>
            <SubmenuDescription>{description}</SubmenuDescription>
            {/* <SubmenuLink
              to={link}
              onClick={() => handleSubmenuLinkClick(key, isAnchor)}
            >
              View All {title}
            </SubmenuLink> */}
          </SubmenuMainInfo>
          <SubmenuTabList $isFeaturedPostVisible={shouldRenderFeaturedPost}>
            {tabs.map((tab) => (
              <SubmenuTabItem key={tab.id}>
                <SubmenuTabLink
                  to={tab.link}
                  onClick={() => handleSubmenuLinkClick(tab.key, tab.isAnchor)}
                >
                  <SubmenuTabIcon src={tab.iconSrc} alt={tab.title} />
                  <SubmenuLinkContent>
                    <SubmenuTabTitle>{tab.title}</SubmenuTabTitle>
                    <SubmenuTabDescription>
                      {tab.description}
                    </SubmenuTabDescription>
                  </SubmenuLinkContent>
                </SubmenuTabLink>
              </SubmenuTabItem>
            ))}
          </SubmenuTabList>
          <FeaturedBlogPost
            featuredPost={featuredPost ?? {}}
            isFeaturedPostVisible={shouldRenderFeaturedPost}
          />
        </SubmenuContentContainer>
      </SubmenuContainer>
    </SubmenuWrapper>
  );
}

export default DesktopSubMenu;
