import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Resources/Footer";
import {
  Wrapper,
  BackToHomeBtn,
  HeroContainer,
  HeroContent,
  HeroDescription,
  HeroImage,
  HeroInfo,
  HeroInfoSeparator,
  HeroLastUpd,
  HeroReadTime,
  HeroSection,
  HeroSubtitle,
  HeroTag,
  HeroAuthor,
  AuthorAvatar,
  AuthorName,
  HeroTitle,
  MainContent,
  MainSection,
  Navigation,
  NavigationTitle,
  NavigationLinks,
  NavigationLink,
  RelatedArticlesContainer,
  PromoContainer,
  RelatedArticlesWrapper,
  MainContainer,
} from "./styles";
import ShareLinks from "./ShareLinks";
import RelatedArticles from "./RelatedArticles";
import StartCampaignPromo from "./StartCampaignPromo";
import StaticPromoSection from "../../../components/StaticPromoSection";
import { CHROME_STORE_EXTENSION_URL } from "../../../utils/constants";
import { displayDateShort, calcReadTime } from "../../../utils/utils";

const NAVIGATION_TAG = "h2";

const Post = ({ postInfo }) => {
  const navigate = useNavigate();

  const [readTime, setReadTime] = useState(0);
  const [links, setLinks] = useState([]);

  const parser = new DOMParser();
  const doc = parser.parseFromString(postInfo.text, "text/html");

  useEffect(() => {
    if (!postInfo.text) {
      return;
    }

    setReadTime(calcReadTime(postInfo.text));

    const headings = Array.from(
      parser
        .parseFromString(postInfo.text, "text/html")
        .querySelectorAll(NAVIGATION_TAG),
    );

    const links = headings.map((heading, index) => ({
      text: heading.textContent,
      id:
        heading.id ||
        `${heading.textContent.toLowerCase().replace(/\s+/g, "-")}-${index}`,
      isActive: index === 0,
    }));

    setLinks(links);
  }, [postInfo.text]);

  useEffect(() => {
    const handleScroll = () => {
      const headings = document.querySelectorAll(NAVIGATION_TAG);
      headings.forEach((heading) => {
        const rect = heading.getBoundingClientRect();
        const id = heading.id;

        if (rect.top <= 20) {
          setLinks((prevLinks) =>
            prevLinks.map((link) => ({ ...link, isActive: link.id === id })),
          );
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [links]);

  const createMarkup = () => {
    const headings = doc.querySelectorAll(NAVIGATION_TAG);
    headings.forEach((heading, index) => {
      if (!heading.id) {
        heading.id = `${heading.textContent
          .toLowerCase()
          .replace(/\s+/g, "-")}-${index}`;
      }
    });
    return { __html: doc.body.innerHTML };
  };

  const openBlogsByTag = (tag) => {
    navigate("/blogs", {
      state: { tag },
    });
  };

  return (
    <Wrapper>
      <HeroSection>
        <HeroContainer>
          <BackToHomeBtn href="/blogs">
            <img src="/images/back.svg" alt="back" />
            Back to blog
          </BackToHomeBtn>
          <HeroContent>
            <HeroDescription>
              <HeroTag onClick={() => openBlogsByTag(postInfo.tag)}>
                {postInfo.tag}
              </HeroTag>
              <HeroTitle>{postInfo.title}</HeroTitle>
              <HeroSubtitle>{postInfo.subTitle}</HeroSubtitle>
              <HeroInfo>
                <HeroLastUpd>
                  {displayDateShort(postInfo.publishDate)}
                </HeroLastUpd>
                <HeroInfoSeparator />
                <HeroReadTime>{readTime} min read</HeroReadTime>
              </HeroInfo>

              <HeroAuthor>
                <AuthorAvatar src={postInfo.profilePicture} alt="author" />
                <AuthorName>{postInfo.writer}</AuthorName>
              </HeroAuthor>
            </HeroDescription>
            <HeroImage>
              <img src={postInfo.heroImage} alt="hero" />
            </HeroImage>
          </HeroContent>
        </HeroContainer>
      </HeroSection>
      <MainContainer>
        <MainSection>
          <Navigation>
            <NavigationTitle>On this page</NavigationTitle>
            <NavigationLinks>
              {links.map((link) => (
                <NavigationLink
                  key={link.id}
                  $isActive={link.isActive}
                  onClick={() => {
                    const element = document.getElementById(link.id);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  {link.text}
                </NavigationLink>
              ))}
            </NavigationLinks>
            <NavigationTitle>Share</NavigationTitle>
            <ShareLinks />
            <StartCampaignPromo />
          </Navigation>
          <MainContent
            className="ql-editor"
            dangerouslySetInnerHTML={createMarkup()}
          />
        </MainSection>
      </MainContainer>

      {postInfo?.relatedArticles?.length > 0 && (
        <RelatedArticlesContainer>
          <RelatedArticlesWrapper>
            <RelatedArticles
              relatedArticles={postInfo.relatedArticles}
              openBlogsByTag={openBlogsByTag}
            />
          </RelatedArticlesWrapper>
        </RelatedArticlesContainer>
      )}

      <PromoContainer>
        <StaticPromoSection
          title="Supercharge your Hubspot CRM"
          text={
            <>
              Say Goodbye to HubSpot Costs, No Upgrades Required. <br />
              Just Choose Your Contacts & Send Unlimited Personalized Emails for
              Free
            </>
          }
          landingId={`blog-${postInfo.id}`}
          onSubscribe={() => window.open(CHROME_STORE_EXTENSION_URL, "_blank")}
        />
      </PromoContainer>

      <Footer />
    </Wrapper>
  );
};

export default Post;
