import styled, { css } from "styled-components";

export const PromoContainer = styled.div`
  width: 100%;
  background: #391952;
  ${({ $forResource }) =>
    $forResource
      ? css`
          border-radius: 16px;
        `
      : css`
          border-radius: 24px;
          margin-top: 51px;

          @media (max-width: 768px) {
            margin-top: 97px;
          }
        `}
`;

export const PromoContent = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    position: relative;
  }

  ${({ $forResource }) =>
    $forResource
      ? css`
          @media (max-width: 768px) {
            flex-direction: column;
          }
        `
      : ""}
`;

export const AnimationContainer = styled.div`
  ${({ $forResource }) =>
    $forResource
      ? css`
          flex: 1;
          max-width: 446px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          order: 2;
          padding: 32px;
          align-self: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          @media (max-width: 768px) {
            max-width: 100%;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-start;
            align-self: flex-end;
          }
        `
      : css`
          flex: 1;
          max-width: 580px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          order: 2;
          padding: 51px 49px 0 0;
          align-self: flex-end;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          @media (max-width: 768px) {
            position: absolute;
            top: -54px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 100%;
            padding: 0;
          }
        `}
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  order: 1;
  flex-grow: 1;
  align-self: flex-end;

  ${({ $forResource }) =>
    $forResource
      ? css`
          padding: 32px 36px 36px 32px;
          max-width: 446px;

          @media (max-width: 768px) {
            padding-bottom: 32px;
            max-width: 100%;
            width: 100%;
          }
        `
      : css`
          padding: 30px 115px 51px 91px;

          @media (max-width: 768px) {
            padding: 170px 20px 33px 20px;
          }
        `}
`;

export const PromoTitle = styled.h2`
  color: #ffffff;
  font-family: Inter;
  font-size: ${({ $isBigTitle }) => ($isBigTitle ? "48px" : "36px")};
  line-height: ${({ $isBigTitle }) => ($isBigTitle ? "58.56px" : "34.2px")};
  font-weight: 800;
  letter-spacing: -0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  span {
    color: #f9ac59;
    font-family: Inter;
    font-size: ${({ $isBigTitle }) => ($isBigTitle ? "48px" : "36px")};
    line-height: ${({ $isBigTitle }) => ($isBigTitle ? "58.56px" : "34.2px")};
    font-weight: 800;
    letter-spacing: -0.04em;
  }

  ${({ $forResource }) =>
    $forResource
      ? css`
          font-size: ${({ $isBigTitle }) => ($isBigTitle ? "48px" : "28px")};
          line-height: ${({ $isBigTitle }) =>
            $isBigTitle ? "58.56px" : "36.4px"};
          font-weight: 600;
          letter-spacing: -0.02em;

          span {
            font-size: ${({ $isBigTitle }) => ($isBigTitle ? "48px" : "28px")};
            line-height: ${({ $isBigTitle }) =>
              $isBigTitle ? "58.56px" : "36.4px"};
            font-weight: 600;
            letter-spacing: -0.02em;
          }

          @media (max-width: 768px) {
            &,
            span {
              font-size: 24px;
              line-height: 31px;
            }
          }
        `
      : css`
          margin: 10px 0;

          @media (max-width: 768px) {
            text-align: center;
            font-size: 27px;
            line-height: 25px;

            span {
              font-size: 27px;
              line-height: 25px;
            }
          }
        `}
`;

export const PromoText = styled.p`
  color: #fff;
  margin-top: 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const TryButton = styled.a`
  text-decoration: none;
  background: #ffffff;
  border-radius: 12px;
  padding: 16px 22px;
  font-family: Inter;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1b0b2b;
  border: none;
  cursor: pointer;
  width: fit-content;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 32px;
`;
