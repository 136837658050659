import styled from "styled-components";

export const Root = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  padding: 96px 104px 0;
  margin-top: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  position: relative;
  z-index: 1;
  color: #ffffff;

  @media (max-width: 768px) {
    margin-top: 70px;
    padding: 40px 16px;
    width: calc(100% - 14px);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 20%);
    background-image: radial-gradient(
      rgba(255, 255, 255, 0.05) 2px,
      transparent 2px
    );
    background-size: 24px 24px;
    background-color: #391952;
    border-radius: 25px;
    z-index: -1;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1200px;
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-weight: 700;
  font-size: 72px;
  line-height: 1.25em;
  margin-bottom: 24px;
  color: #ffffff;
  letter-spacing: 0%;
  text-align: center;

  span {
    font-family: Poppins;
    font-weight: 700;
    font-size: inherit;
    line-height: inherit;
    color: #ffffff;
    border-radius: 16px;
    padding: 0;
    display: inline-block;
    position: relative;
    white-space: pre-wrap;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      width: calc(100% + 20px);
      height: 1.25em;
      background-color: #92abfc;
      border-radius: 16px;
      z-index: -1;
    }
  }

  @media (max-width: 768px) {
    font-size: 48px;
  }
`;

export const Subtitle = styled.p`
  font-family: Poppins;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 40px;
  max-width: 65%;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const Form = styled.form`
  width: 100%;
  padding: 0 60px;
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
`;

export const Input = styled.input`
  flex: 1;
  height: 48px;
  padding: 0 25px;
  background: #ffffff;
  border: none;
  border-radius: 13.5px;
  font-size: 16px;
  outline: none;
  box-shadow: 0px 4px 4px 0px #00000040 inset;

  &::placeholder {
    color: #cccccc;
    font-family: Poppins;
    font-weight: 400;
    font-size: 15px;
    line-height: 26.47px;
    letter-spacing: 0%;
  }
`;

export const Button = styled.button`
  height: 48px;
  padding: 0 45px;
  border: none;
  border-radius: 8px;
  color: #391952;
  background: #f9ac59;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -3%;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #fa9f3d;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 45px;
  position: relative;
  border: 5px solid #fff;

  @media (max-width: 768px) {
    border-radius: 20px;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Features = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 32px;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 24px;

  &:before {
    content: "•";
    color: #ffb800;
    font-size: 24px;
    line-height: 16px;
  }
`;
