import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -265px auto 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    margin: -90px auto 0 auto;
  }
`;
