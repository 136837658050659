import styled, { css } from "styled-components";

export const Root = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ variant }) => (variant === "scroll" ? "104px" : "20px")};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Badge = styled.div`
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  color: #391952;
  background-color: rgba(57, 25, 82, 0.08);
  background-image: url("/images/codepen.svg");
  background-repeat: no-repeat;
  background-position: 12px center;
  padding: 8px 10px 8px 40px;
  border-radius: 8px;
  margin: 145px 0 16px;
  width: 146px;
  height: 37px;
  border: 1px solid #39195214;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const HighlightedText = styled.span`
  color: #92abfc;
  font-family: Poppins;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: 0%;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 64px;
  line-height: 78.08px;
  text-align: center;
  margin-bottom: 16px;

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          font-family: Poppins;
          font-size: 48px;
          line-height: 1.25em;
          color: #391952;

          @media (max-width: 768px) {
            font-size: 40px;
          }
        `
      : css`
          font-family: Poppins;
          color: #000;
          margin-bottom: 170px;
        `}
`;

export const Subtitle = styled.p`
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  color: #391952;
  max-width: 600px;
  margin-bottom: 56px;
  opacity: 0.7;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

export const ScrollContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: #f3f4f6;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 3px;
  }

  scrollbar-width: thin;
  scrollbar-color: #d1d5db #f3f4f6;
`;

export const StepsContainer = styled.div`
  width: ${({ variant }) => (variant === "scroll" ? "fit-content" : "100%")};
  display: flex;
  gap: 40px;
  position: relative;

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          flex-direction: row;

          @media (max-width: 768px) {
            flex-direction: column;
          }
        `
      : css`
          flex-direction: column;
        `}
`;

export const Step = styled.div`
  position: relative;
  display: flex;
  gap: 30px;

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          flex-direction: column;
          flex: 0 0 32vw;
          scroll-snap-align: start;
          gap: 24px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            position: relative;
            margin-right: 0;
          }
        `
      : css`
          flex-direction: row;
          width: 100%;
        `}
`;

export const StepText = styled.div`
  ${({ variant }) =>
    variant === "scroll"
      ? css`
          width: 100%;
        `
      : css`
          width: calc(45% - 30px);
        `}
`;

export const StepTitle = styled.h3`
  font-weight: 800;
  font-size: 35px;
  line-height: 1.2em;
  letter-spacing: 0%;
  margin-bottom: 24px;
  position: relative;
  padding-left: 35px;

  &:before {
    content: "${({ $number }) => $number}.";
    font-family: Inter;
    font-weight: 800;
    font-size: 35px;
    line-height: 1.2em;
    position: absolute;
    left: 0;
    color: #000;
  }

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          font-family: Poppins;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0%;
          color: #391952;
          padding-left: 30px;

          &:before {
            font-family: "Poppins", sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #391952;
            left: 10px;
          }
        `
      : css`
          font-family: Inter;
          color: #000;

          &:before {
            content: "${({ $number }) => $number}.";
            font-family: Inter;
            font-weight: 800;
            font-size: 35px;
            line-height: 1.2em;
            position: absolute;
            left: 0;
            color: #000;
          }
        `}
`;

export const StepDescription = styled.ul`
  font-size: 16px;
  line-height: 24px;
  color: #391952;
  margin-bottom: 24px;

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          padding-left: 0;
          list-style: none;
        `
      : css`
          padding-left: 40px;
          list-style: none;
        `}
`;

export const StepItem = styled.li`
  position: relative;

  &:before {
    content: "";
    height: 20px;
    width: 20px;
    background: url("/images/checkbox.svg") no-repeat center center;
    position: absolute;
    left: -40px;
    top: 8px;
  }

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          opacity: 0.7;
          margin-bottom: 8px;
          color: #745d83;
          padding-left: 28px;

          &:before {
            background: url("/images/check.svg") no-repeat center center;
            position: absolute;
            left: 0px;
            top: 2px;
          }
        `
      : css`
          font-family: Poppins;
          font-size: 20px;
          line-height: 37.9px;
          font-weight: 300;

          &:before {
            background: url("/images/checkbox.svg") no-repeat center center;
            position: absolute;
            left: -40px;
            top: 8px;
          }
        `}
`;

export const StepImage = styled.div`
  border-radius: 16px;
  overflow: hidden;
  background-color: #f5f5f5;

  ${({ variant }) =>
    variant === "scroll"
      ? css`
          width: 100%;
          aspect-ratio: 16/10;
          border: 4px solid #fff;
          box-shadow: 0px 16px 32px -12px #0e121b1a;
        `
      : css`
          width: 55%;
          aspect-ratio: 16/9;
        `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
