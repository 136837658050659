import { useState } from "react";

const data = [
  {
    id: 1,
    tabTitle: "Step 1",
    title: "Step 1: Install Reach for free from Google Chrome Store",
    imageSrc: "/images/landing-features/marketing-power-2/step-1.png",
  },
  {
    id: 2,
    tabTitle: "Step 2",
    title: "Step 2: Connect Reach to your Hubspot Account",
    imageSrc: "/images/landing-features/marketing-power-2/step-2.png",
  },
  {
    id: 3,
    tabTitle: "Step 3",
    title: "Step 3: Select your Contacts from Hubspot",
    imageSrc: "/images/landing-features/marketing-power-2/step-3.png",
  },
  {
    id: 4,
    tabTitle: "Step 4",
    title:
      "Step 4 : Set goals and personalize every email using your contact's data",
    imageSrc: "/images/landing-features/marketing-power-2/step-4.png",
  },
  {
    id: 5,
    tabTitle: "Step 5",
    title: "Step 5 : Hit Send and Start Seeing Higher Engagements",
    imageSrc: "/images/landing-features/marketing-power-2/step-5.png",
  },
];

export const useFeatures = () => {
  const [features, setFeatures] = useState({
    allFeatures: data.map((feature) => ({
      id: feature.id,
      title: feature.title,
      imageSrc: feature.imageSrc,
    })),
    activeFeature: data[0],
    tabs: data.map((feature) => ({
      id: feature.id,
      title: feature.tabTitle,
    })),
  });

  const handleFeatureClick = (featureId) => {
    const selectedFeature = data.find((feature) => feature.id === featureId);

    if (selectedFeature) {
      setFeatures({
        ...features,
        activeFeature: selectedFeature,
      });
    }
  };

  return {
    allFeatures: features.allFeatures,
    activeFeature: features.activeFeature,
    tabs: features.tabs,
    handleFeatureClick,
  };
};
