import {
  addEmailToWaitList,
  FORCE_CORPORATE_EMAILS,
} from "../services/waitList";
import { EMAIL_REGEX, SHOW_NAVBAR_PATHS } from "./constants";

const validateEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

const addToWaitList = async (email, landingId) => {
  if (email === "") {
    return;
  }

  let invalidEmailMessage;
  if (!validateEmail(email)) {
    invalidEmailMessage = "Invalid email address";
  } else if (
    (email.toString().toLowerCase().endsWith("gmail.com") ||
      email.toString().toLowerCase().endsWith("yahoo.com") ||
      email.toString().toLowerCase().endsWith("hotmail.com") ||
      email.toString().toLowerCase().endsWith("outlook.com") ||
      email.toString().toLowerCase().endsWith("mail.com") ||
      email.toString().toLowerCase().endsWith("icloud.com")) &&
    FORCE_CORPORATE_EMAILS
  ) {
    invalidEmailMessage = "Please use your company email address";
  }

  if (invalidEmailMessage) {
    return invalidEmailMessage;
  }

  return await addEmailToWaitList(email, landingId);
};

const sanitizeHtml = (html) => {
  // Sanitize the text to remove any potentially dangerous HTML
  return html.replace(/<script.*?>.*?<\/script>/gi, "");
};

const waitForElementBySelector = (selector, retries = 5, delay = 500) => {
  return new Promise((resolve) => {
    let attempts = 0;

    const interval = setInterval(() => {
      const elm = document.querySelector(selector);

      if (elm) {
        clearInterval(interval);
        resolve(elm);
      } else if (attempts >= retries) {
        clearInterval(interval);
        resolve(null);
      }
      attempts += 1;
    }, delay);
  });
};

const waitForElement = (id, retries = 5, delay = 500) => {
  return waitForElementBySelector(`#${id}`, retries, delay);
};

const displayDateShort = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const calcReadTime = (text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");
  const textContent = doc.body.textContent || "";
  const words = textContent.split(/\s+/).filter(Boolean).length || 0;
  const readTime = Math.round(words / 200) || 1;
  return readTime;
};

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const prepareGuideTitle = (text) => {
  return text.replace(/\bguide\b\s*$/i, "").trim();
};

const shouldShowNavbar = (pathname) => {
  return SHOW_NAVBAR_PATHS.some((path) =>
    path.length > 1 ? pathname.startsWith(path) : pathname === path,
  );
};

export {
  addToWaitList,
  sanitizeHtml,
  waitForElementBySelector,
  waitForElement,
  displayDateShort,
  calcReadTime,
  randomNumber,
  prepareGuideTitle,
  shouldShowNavbar,
};
