import {
  fetchGetMegaPageData,
  fetchGetMegaPage,
  fetchGetMegaPages,
} from "./queries";

const getMegaPages = async (data, isQaEnv) => {
  try {
    const result = await fetchGetMegaPages(data, isQaEnv);

    if (result.message) {
      return { success: false, message: result.message };
    }

    return { ...result, success: true };
  } catch (error) {
    return { success: false, message: "Get Mega Pages failed" };
  }
};

const getMegaPage = async (link, isQaEnv) => {
  try {
    const result = await fetchGetMegaPage(link, isQaEnv);

    if (result.message) {
      return result;
    }
    return { success: true, megaPage: result.megaPage };
  } catch (error) {
    return { success: false, message: "Get Mega Page failed" };
  }
};

const getMegaPageData = async (id, isQaEnv) => {
  try {
    const result = await fetchGetMegaPageData(id, isQaEnv);

    if (result.message) {
      return result;
    }

    return { success: true, megaPageData: result.seoData };
  } catch (error) {
    return { success: false, message: "Get Mega Page data failed" };
  }
};

export { getMegaPages, getMegaPage, getMegaPageData };
