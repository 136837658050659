import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../../../contexts/NavigationContext";
import { NAVIGATION_TAB_LIST } from "../../../utils/constants";
import { waitForElement } from "../../../utils/utils";

export const useNavigation = () => {
  const navigate = useNavigate();
  const { selectedTab, setSelectedTab } = useContext(NavigationContext);

  const handleLogoClick = useCallback(() => {
    navigate("/");
    setSelectedTab(NAVIGATION_TAB_LIST[0]);
  }, [navigate, setSelectedTab]);

  const handleTabClick = useCallback(async (tab) => {
    if (!tab.isAnchor) return;

    const section = await waitForElement(tab.key);

    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return {
    selectedTab,
    setSelectedTab,
    handleLogoClick,
    handleTabClick,
  };
};

export default useNavigation;
