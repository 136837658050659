import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 1;

  margin: 0 auto;
  width: 1200px;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const Title = styled.p`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 700;
  font-size: 72px;
  line-height: 90px;
  color: #fff;

  @media (max-width: 767px) {
    padding: 0 9px;
    font-size: 40px;
    line-height: 50px;
  }
`;

export const Description = styled.p`
  margin: 16px 0 0;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffffb2;

  @media (max-width: 767px) {
    padding: 0 9px;
  }
`;

export const ArticleCardContainer = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    padding: 0 9px;
  }
`;

export const ArticleCard = styled.div`
  margin-top: 56px;
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 16px 32px -12px #0e121b1a;
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 767px) {
    margin-top: 40px;
    margin-top: 40px;
    flex-direction: column;
  }
`;

export const ArticleContent = styled.div`
  padding: 0 25px;
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 0;
    width: 100%;
  }
`;

export const ArticleImageContainer = styled.div`
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 100%;
    max-height: 560px;
    object-fit: cover;
    border-radius: 12px;
  }

  @media (min-width: 1441px) {
    img {
      height: 560px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    img {
      flex-grow: 1;
      min-height: 400px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ArticleTag = styled.div`
  padding: 4px 9px;
  width: fit-content;
  height: fit-content;
  min-height: 28px;
  border-radius: 8px;
  background: #ebf3ff;
  color: #3e89ff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

export const ArticleTitle = styled.div`
  margin-top: 16px;
  width: fit-content;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.03em;
  color: #0a0a0a;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const ArticleSubTitle = styled.div`
  margin-top: 16px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #717784;
`;

export const Meta = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #717784;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
`;

export const MetaSeparator = styled.span`
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 50%;
`;

export const Author = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-family: ${({ theme }) => theme.fonts.interPrimary};
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #0e121b;
  }

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
`;
