import styled from "styled-components";

export const Section = styled.section`
  padding-top: 120px;

  .swiper-button-prev,
  .swiper-button-next {
    top: 40%;

    &::after {
      color: #000;
      font-size: 17px;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 21px;
      height: 1px;
      background-color: #000;
    }

    @media (max-width: 767px) {
      top: unset;
      bottom: 20px;
    }
  }

  .swiper-button-prev:before {
    left: 10px;
  }

  .swiper-button-next:before {
    right: 10px;
  }
`;

export const Title = styled.h4`
  font-family: Inter;
  font-weight: 800;
  color: #141414;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  max-width: 682px;
  margin: 0 auto 68px;

  @media (max-width: 767px) {
    max-width: 290px;
    margin-bottom: 40px;
    font-size: 28px;
  }
`;
