import React from "react";

import { Container, Content, Step, Image, ImageContainer } from "./styles";

function Description({ title, imageSrc }) {
  return (
    <Container>
      <Content>
        <Step>{title}</Step>
        <ImageContainer>
          <Image src={imageSrc} alt={title} />
        </ImageContainer>
      </Content>
    </Container>
  );
}

export default Description;
