import React from "react";

import { useMediaQuery } from "react-responsive";
import { SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import Description from "./Description";

import { SwiperStyled, DescriptionContainer } from "./styles";

import "swiper/css";
import "swiper/css/navigation";

const MOBILE_BREAKPOINT = 768;

const MobileDescription = ({
  allFeatures,
  activeFeature,
  handleSlideChange,
  swiperRef,
}) => (
  <DescriptionContainer>
    <SwiperStyled
      ref={swiperRef}
      slidesPerView={1}
      onSlideChange={handleSlideChange}
      modules={[Navigation]}
      initialSlide={activeFeature.id - 1}
      spaceBetween={0}
    >
      {allFeatures.map((feature) => (
        <SwiperSlide key={feature.id}>
          <Description title={feature.title} imageSrc={feature.imageSrc} />
        </SwiperSlide>
      ))}
    </SwiperStyled>
  </DescriptionContainer>
);

const DesktopDescription = ({ activeFeature }) => (
  <DescriptionContainer>
    <Description
      title={activeFeature.title}
      imageSrc={activeFeature.imageSrc}
    />
  </DescriptionContainer>
);

const AdaptiveDescription = ({
  allFeatures,
  activeFeature,
  handleSlideChange,
  swiperRef,
}) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_BREAKPOINT });

  return isMobile ? (
    <MobileDescription
      allFeatures={allFeatures}
      activeFeature={activeFeature}
      handleSlideChange={handleSlideChange}
      swiperRef={swiperRef}
    />
  ) : (
    <DesktopDescription activeFeature={activeFeature} />
  );
};

export default AdaptiveDescription;
