import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  position: relative;
  z-index: 1;
  color: #ffffff;

  @media (max-width: 1200px) {
    padding: 120px 60px 60px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 64px;
  line-height: 78.08px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #ffffff;
  letter-spacing: 0%;
  text-align: center;
`;

export const Highlight = styled.span`
  font-family: Poppins;
  font-size: 64px;
  line-height: 78.08px;
  color: #f9ac59;
  font-weight: 700;
`;

export const Subtitle = styled.p`
  font-family: Poppins;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 60px;
`;

export const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Features = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 32px;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 24px;

  &:before {
    content: "•";
    color: #ffb800;
    font-size: 24px;
    line-height: 16px;
  }
`;
