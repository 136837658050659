import React from "react";

import Steps from "./Steps";

import { Container, Title } from "./styles";

function Description() {
  return (
    <Container>
      <Title>
        Equip your basic version of Hubspot <br /> to send tons of personalized
        emails for Free
      </Title>
      <Steps />
    </Container>
  );
}

export default Description;
