/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Benefits from "./Benefits";
import Categories from "./Categories";
import Footer from "../Resources/Footer";
import {
  BlogsWrapper,
  MainContent,
  ArticleGrid,
  BenefitsContainer,
  LoaderContainer,
  Loader,
  EmptyData,
  ArticlesWrapper,
  ArticleGridWrapper,
  PromoSectionWrapper,
  LoadMoreBtn,
} from "./styles";
import { AppContext } from "../../contexts/AppContext";
import { getMegaPages } from "../../services/megaPage";
import { uniq } from "lodash";
import ArticleCard from "./ArticleCard";
import PromoSection from "../Blogs/PromoSection";
import { buildMegaPageLink } from "../../utils/helpers";
import { randomNumber } from "../../utils/utils";

const LIMIT = 6;

const Guides = () => {
  const { isQaEnv } = useContext(AppContext);
  const navigate = useNavigate();

  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [guides, setGuides] = useState([]);
  const [topGuide, setTopGuide] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);

  const fetchGuides = async (params) => {
    const data = {
      limit: LIMIT,
      offset: params?.clearAfterSearch ? 0 : guides.length,
    };

    [["tag", params?.tag ?? selectedTag]].forEach(([k, v]) => {
      if (v) {
        data[k] = v;
      }
    });

    setIsLoading(!data.offset);
    setIsLoadingMore(!!data.offset);

    const result = await getMegaPages(data, isQaEnv);

    setIsLoadingStart(false);
    setIsLoading(false);
    setIsLoadingMore(false);

    const resGuides = result.megaPages;

    if (!result?.success || !resGuides) {
      setHasMore(false);
      return;
    }

    const isAll = !data.tag && !data.search && !data.offset;

    if (isAll || result.tags) {
      setTags(
        uniq(result.tags || resGuides.map((p) => p.tag || p.pageHeaderText))
          .filter(Boolean)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
      );
    }

    if (isAll) {
      setTopGuide(resGuides[randomNumber(0, resGuides.length - 1)]);
    }

    setGuides(!data.offset ? resGuides : [...guides, ...resGuides]);

    setHasMore(result.meta?.hasMore || false);
  };

  useEffect(() => {
    fetchGuides();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSelectTag = (val) => {
    setSelectedTag(val);
    fetchGuides({ clearAfterSearch: true, tag: val ?? "" });
  };

  // We do it because we get content which created\edited by React Quill lib.
  const injectLink = () => {
    const link = document.createElement("link");

    link.rel = "stylesheet";
    link.href =
      "https://cdnjs.cloudflare.com/ajax/libs/quill/2.0.2/quill.snow.css";
    link.id = "quill-style";
    document.head.appendChild(link);

    return link;
  };

  useEffect(() => {
    const link = injectLink();

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  if (isLoadingStart) {
    return (
      <LoaderContainer>
        <Loader>
          <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
        </Loader>
      </LoaderContainer>
    );
  }

  const handleLoadMore = () => {
    fetchGuides();
  };

  const handleOpenGuide = (item) => {
    navigate(buildMegaPageLink(item));
  };

  return (
    <BlogsWrapper>
      {isLoading && (
        <LoaderContainer $noBgAndFixed={true}>
          <Loader>
            <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
          </Loader>
        </LoaderContainer>
      )}

      <BenefitsContainer>
        <Benefits data={topGuide} onClick={() => handleOpenGuide(topGuide)} />
      </BenefitsContainer>

      <MainContent>
        <Categories
          categories={tags}
          activeCategory={selectedTag}
          onSelect={handleSelectTag}
        />

        <ArticlesWrapper>
          {!guides.length ? (
            <ArticleGridWrapper>
              <EmptyData>No guides to show</EmptyData>
            </ArticleGridWrapper>
          ) : (
            <ArticleGridWrapper>
              <ArticleGrid>
                {guides.map((item, index) => (
                  <ArticleCard
                    key={index}
                    data={item}
                    onClick={() => handleOpenGuide(item)}
                  />
                ))}
              </ArticleGrid>
            </ArticleGridWrapper>
          )}

          {hasMore && (
            <LoadMoreBtn
              $disabled={isLoadingMore || isLoading}
              onClick={handleLoadMore}
            >
              {isLoadingMore && (
                <Loader $inBtn={true}>
                  <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
                </Loader>
              )}
              Load More Guides
              <img src="/images/arrow-down.svg" alt="" />
            </LoadMoreBtn>
          )}
        </ArticlesWrapper>

        <PromoSectionWrapper>
          <PromoSection />
        </PromoSectionWrapper>
      </MainContent>

      <Footer />
    </BlogsWrapper>
  );
};

export default Guides;
