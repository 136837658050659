import styled, { css } from "styled-components";

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;

  @media (max-width: 977px) {
    position: fixed;
    top: 70px;
    right: 0;
    width: fit-content;
    height: fit-content;
    z-index: 11;
    flex-direction: column;
    align-items: flex-start;
    background: #612686;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    border-radius: 8px 0 0 8px;
    transition: all 0.65s ease-in-out;

    ${({ $isOpen }) =>
      $isOpen
        ? css`
            right: 0;
          `
        : css`
            right: -100vw;
          `}
  }

  @media (max-width: 1070px) {
    margin-right: 0;
    padding-right: 14px;
  }
`;
