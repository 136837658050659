import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const FeaturedPostContainer = styled.div`
  ${({ $isFeaturedPostVisible }) =>
    $isFeaturedPostVisible
      ? css`
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
          max-width: 300px;
        `
      : css`
          display: none;
        `}
`;

export const ImageContainer = styled.div`
  margin: 12px 12px 0 12px;
  width: auto;
  height: 196px;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const Content = styled.div`
  margin: 8px 12px 12px 12px;
`;

export const Tag = styled.span`
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -2%;
  vertical-align: middle;
  color: #4f4f4f;
`;

export const Title = styled.div`
  margin-top: 8px;
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -2%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReadMoreLink = styled(Link)`
  margin-top: 12px;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  color: #000;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url("/images/arrow-right-black.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 4px;
  }
`;
