import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 4px;
`;

export const Link = styled.a`
  cursor: pointer;
  padding: 10px;
  width: 40px;
  height: 40px;

  img {
    width: 20px;
    height: 20px;
  }
`;
