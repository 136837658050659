import React from "react";
import {
  Container,
  Content,
  ImageContainer,
  Tag,
  Title,
  Meta,
  MetaSeparator,
} from "./styles";
import { calcReadTime, displayDateShort } from "../../../utils/utils";

function ArticleCard({ data, order, onClick, openBlogsByTag }) {
  return (
    <Container $order={order} id={`blog-${data.id}`}>
      <ImageContainer $order={order}>
        <img src={data.heroImage} alt="Illustration" />
      </ImageContainer>
      <Content $order={order}>
        <Tag onClick={openBlogsByTag}>{data.tag}</Tag>
        <Title $order={order} onClick={onClick}>
          {data.title}
        </Title>
        <Meta>
          {displayDateShort(data.publishDate)} <MetaSeparator />{" "}
          {calcReadTime(data.text)} min read
        </Meta>
      </Content>
    </Container>
  );
}

export default ArticleCard;
