import styled from "styled-components";
import { Swiper } from "swiper/react";

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const SwiperStyled = styled(Swiper)`
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 24px;
  }

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
`;
