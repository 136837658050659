import React from "react";
import { CHROME_STORE_EXTENSION_URL } from "../../../utils/constants";
import { InstallAppLink, TryLink } from "../styles";

const ReachButton = ({
  isForHubspot,
  isExtensionInstalled,
  onSubscribe,
  forMobile,
  isNavbarVisible,
}) => {
  const handleTryBtnClick = (event) => {
    event.preventDefault();

    if (onSubscribe) {
      onSubscribe();
      return;
    }

    window.open(CHROME_STORE_EXTENSION_URL, "_blank");
  };

  if (isForHubspot) {
    return isExtensionInstalled ? (
      <InstallAppLink
        href={CHROME_STORE_EXTENSION_URL}
        target="_blank"
        onClick={handleTryBtnClick}
      >
        Install now - it's Free!
      </InstallAppLink>
    ) : null;
  }

  return (
    <TryLink
      $forMobile={forMobile}
      $isNavbarVisible={isNavbarVisible}
      onClick={handleTryBtnClick}
      target="_blank"
    >
      Try Reach
    </TryLink>
  );
};

export default ReachButton;
