import MainHeader from "../../../../components/MainHeader";

const Header = ({
  isForHubspot = false,
  onSubscribe,
  isExtensionInstalled,
}) => {
  return (
    <MainHeader
      isForLanding={true}
      isForHubspot={isForHubspot}
      onSubscribe={onSubscribe}
      isExtensionInstalled={isExtensionInstalled}
    />
  );
};

export default Header;
