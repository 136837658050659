import React from "react";
import {
  PromoContainer,
  PromoContent,
  PromoTitle,
  PromoText,
  AnimationContainer,
  TextContainer,
  FormContainer,
} from "./styles";
import EmailSubscribeForm from "../../pages/LandingsSet/components/EmailSubscribeForm";

const StaticPromoSection = ({ title, text, landingId, onSubscribe }) => {
  return (
    <PromoContainer>
      <PromoContent>
        <TextContainer>
          <PromoTitle>
            {title ||
              `Turn the Free version of Your HubSpot into an Email Powerhouse!`}
          </PromoTitle>
          <PromoText>
            {text ||
              `Tell Reach your campaign goals and Reach will communicate with each
            of your contacts on your behalf`}
          </PromoText>
          <FormContainer>
            <EmailSubscribeForm
              landingId={landingId}
              onSuccess={onSubscribe}
              buttonText="Get Started"
              isYellow={true}
            />
          </FormContainer>
        </TextContainer>
        <AnimationContainer>
          <img src="/images/contacts-preview-small.png" alt="contacts-preview" />
        </AnimationContainer>
      </PromoContent>
    </PromoContainer>
  );
};

export default StaticPromoSection;
