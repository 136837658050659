import React from "react";

import {
  StyledMenuItem,
  SubmenuList,
  SubmenuItem,
  StyledLink,
  Name,
} from "./styles";

function MobileSubMenu({ data, handleSubmenuLinkClick }) {
  const { tabs } = data;

  return (
    <StyledMenuItem>
      <SubmenuList>
        {tabs.map((tab, index) => (
          <SubmenuItem key={tab.id || index}>
            <StyledLink
              to={tab.link}
              onClick={() => handleSubmenuLinkClick(tab.key, tab.isAnchor)}
            >
              <Name $shouldNewDesignNavbar={true}>{tab.title}</Name>
            </StyledLink>
          </SubmenuItem>
        ))}
      </SubmenuList>
    </StyledMenuItem>
  );
}

export default MobileSubMenu;
