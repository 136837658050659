import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  position: relative;
  z-index: 1;
  color: #ffffff;
`;

export const Content = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1`
  font-family: Inter;
  font-size: 56px;
  line-height: 125%;
  font-weight: 700;
  margin-bottom: 24px;
  color: #ffffff;
  letter-spacing: 0%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 40px;
    padding: 0 20px;
  }
`;

export const Highlight = styled.span`
  font-weight: 700;
  color: #f9ac59;
`;

export const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Subtitle = styled.p`
  margin: 0 auto 40px auto;
  font-family: Inter;
  color: #fff;
  font-weight: 400;
  line-height: 150%;
  font-size: 20px;
  letter-spacing: 0%;
  text-align: center;
  max-width: 585px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0 20px;
  }
`;
