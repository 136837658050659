import React from "react";
import styled from "styled-components";

const ProductHuntLogo = () => {
  return (
    <Container>
      <TopLink
        href="https://www.producthunt.com/posts/reach-from-salesstream-ai?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-reach&#0045;from&#0045;salesstream&#0045;ai"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=692375&theme=light&period=daily"
          alt="Reach from SalesStream.ai - World's simplest free-forever email & SMS campaign builder | #3 Product of the day_Product Hunt"
          style={{ width: "250px", height: "54px" }}
          width="250"
          height="54"
        />
      </TopLink>
      <Link
        href="https://www.producthunt.com/posts/reach-from-salesstream-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-reach&#0045;from&#0045;salesstream&#0045;ai"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=692375&theme=light"
          alt="Reach from SalesStream.ai - World's simplest free-forever email & SMS campaign builder | 400 upvotes_Product Hunt"
          style={{ width: "250px", height: "54px" }}
          width="250"
          height="54"
        />
      </Link>
    </Container>
  );
};

export default ProductHuntLogo;

const Container = styled.div`
  clip-path: inset(50%);
  white-space: nowrap;
`;

const TopLink = styled.a`
  position: fixed;
  bottom: 85px;
  left: 20px;
  z-index: 99;
`;
const Link = styled.a`
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 99;
`;
