import React, { useState } from "react";
import {
  CategoriesContainer,
  CategoriesDropdownButton,
  CategoriesDropdownContainer,
  CategoriesDropdownTitle,
  CategoryButtons,
  CategoryButton,
} from "./styles";

const ALL = "All Guides";

const Categories = ({ categories, activeCategory, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (val) => {
    onSelect(val);
    setIsOpen(false);
  };

  return (
    <div>
      <CategoriesDropdownContainer>
        <CategoriesDropdownTitle>{ALL}</CategoriesDropdownTitle>
        <CategoriesDropdownButton
          $isActive={true}
          $isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          {activeCategory || ALL}
        </CategoriesDropdownButton>
      </CategoriesDropdownContainer>

      <CategoriesContainer $isOpen={isOpen}>
        <CategoryButtons>
          <CategoryButton
            $isActive={!activeCategory}
            onClick={() => handleSelect(null)}
          >
            {ALL}
          </CategoryButton>
          {categories.map((category) => {
            const isActive = category === activeCategory;
            return (
              <CategoryButton
                key={category}
                $isActive={isActive}
                onClick={() => handleSelect(isActive ? null : category)}
              >
                {category}
              </CategoryButton>
            );
          })}
        </CategoryButtons>
      </CategoriesContainer>
    </div>
  );
};

export default Categories;
