import React from "react";

import { useFeatures } from "./useFeatures";
import { useSwiperNavigation } from "./useSwiperNavigation";

import TabList from "./TabList";
import AdaptiveDescription from "./AdaptiveDescription";
import NavigationControls from "./NavigationControls";

import { Container } from "./styles";

function Features() {
  const { allFeatures, activeFeature, tabs, handleFeatureClick } =
    useFeatures();
  const { swiperRef, handleSlideChange, slideNext, slidePrev } =
    useSwiperNavigation(handleFeatureClick);

  return (
    <Container>
      <TabList
        tabs={tabs}
        activeTab={activeFeature.id}
        onTabClick={handleFeatureClick}
      />
      <AdaptiveDescription
        allFeatures={allFeatures}
        activeFeature={activeFeature}
        handleSlideChange={handleSlideChange}
        swiperRef={swiperRef}
      />
      <NavigationControls
        currentStep={activeFeature.id}
        totalSteps={tabs.length}
        onNext={slideNext}
        onPrev={slidePrev}
      />
    </Container>
  );
}

export default Features;
