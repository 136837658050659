import styled from "styled-components";

export const ResourcesWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  position: relative;
  background: linear-gradient(
    263.83deg,
    #fef8e966 61.82%,
    rgba(246, 247, 252, 0.4) 88.44%
  );
  background-color: #fffcf5;
`;

export const BenefitsContainer = styled.div`
  background-color: #391952;
  position: relative;
  width: 100%;
  padding: 200px 15px 124px 15px;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 767px) {
    padding-top: 176px;
    padding-bottom: 100px;
  }
`;

export const MainContent = styled.div`
  margin: 0 auto;
  padding: 72px 0 38px;
  width: 1200px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    column-gap: 48px;
  }

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (max-width: 767px) {
    padding: 24px 15px 25px;
  }
`;

export const AccordionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AccordionGridWrapper = styled.div``;

export const AccordionGrid = styled.div`
  @media (max-width: 768px) {
    padding: 0 9px;
  }
`;

export const PromoSectionWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 0 9px;
  }
`;

export const AccordionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  align-self: flex-start;
`;

export const LoaderContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, #391952 120px, transparent 120px);
  position: relative;
  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 977px) {
    background: linear-gradient(180deg, #391952 84px, transparent 84px);
  }
`;

export const Loader = styled.div`
  @keyframes wait-animation-s {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: wait-animation-s -1ms 800ms ease-in;

  @keyframes slide {
    0% {
      transform: translate(0, 0);
    }
    2% {
      transform: translate(${({ $widthEl }) => $widthEl}px, 0);
    }
    12.5% {
      transform: translate(${({ $widthEl }) => $widthEl}px, 0);
    }
    15.5% {
      transform: translate(${({ $widthEl }) => 2 * $widthEl}px, 0);
    }
    25% {
      transform: translate(${({ $widthEl }) => 2 * $widthEl}px, 0);
    }
    27% {
      transform: translate(
        ${({ $widthEl }) => 2 * $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    37.5% {
      transform: translate(
        ${({ $widthEl }) => 2 * $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    39.5% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    50% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    52% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => 2 * $widthEl}px
      );
    }
    62.5% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => 2 * $widthEl}px
      );
    }
    64.5% {
      transform: translate(0, ${({ $widthEl }) => 2 * $widthEl}px);
    }
    75% {
      transform: translate(0, ${({ $widthEl }) => 2 * $widthEl}px);
    }
    77% {
      transform: translate(0, ${({ $widthEl }) => $widthEl}px);
    }
    87.5% {
      transform: translate(0, ${({ $widthEl }) => $widthEl}px);
    }
    89.5% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes gradient1 {
    from {
      stop-color: #4383b8;
    }
    50% {
      stop-color: #8013b9;
    }
    to {
      stop-color: #4383b8;
    }
  }
  @keyframes gradient2 {
    from {
      stop-color: #4aa06c;
    }
    50% {
      stop-color: #b22358;
    }
    to {
      stop-color: #4aa06c;
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg#loading {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  svg#loading #gradient > stop[offset="0%"] {
    animation: gradient1 15s ease 0s infinite;
  }
  svg#loading #gradient > stop[offset="100%"] {
    animation: gradient2 15s ease 0s infinite;
  }
  svg#loading .rect {
    animation: slide 15s ease infinite;
  }
  svg#loading #rect1 {
    animation-delay: 0s;
  }
  svg#loading #rect2 {
    animation-delay: -2.1428571428571s;
  }
  svg#loading #rect3 {
    animation-delay: -4.2857142857143s;
  }
  svg#loading #rect4 {
    animation-delay: -6.4285714285714s;
  }
  svg#loading #rect5 {
    animation-delay: -8.5714285714286s;
  }
  svg#loading #rect6 {
    animation-delay: -10.714285714286s;
  }
  svg#loading #rect7 {
    animation-delay: -12.857142857143s;
  }
`;

export const EmptyData = styled.p`
  margin: 22px auto 0;
  padding: 72px 0 38px;
  width: 1200px;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.04em;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  @media (max-width: 767px) {
    padding: 24px 15px 25px;
  }
`;
