import React from "react";
import {
  Container,
  Content,
  Footer,
  ImageContainer,
  Rating,
  SubTitle,
  Tag,
  Title,
  ViewGuide,
} from "./styles";
import { prepareGuideTitle } from "../../../utils/utils";

function ArticleCard({ data, onClick }) {
  return (
    <Container>
      <ImageContainer
        $noImage={!data.illustration}
        data-header={data.pageHeaderText}
      >
        {!!data.illustration && (
          <img src={data.illustration} alt="Illustration" />
        )}
      </ImageContainer>
      <Content>
        <Tag>{data.tag || data.pageHeaderText}</Tag>
        <Title>{`The Ultimate ${prepareGuideTitle(
          data.pageHeaderText,
        )} Guide`}</Title>
        <SubTitle>
          {data.shortDescription ||
            data.description ||
            `Get Answers to all your ${data.pageHeaderText.toLowerCase()} queries in one place`}
        </SubTitle>
        <Footer>
          <ViewGuide onClick={onClick}>View Guide</ViewGuide>
          {!!data.rating && (
            <Rating>
              {typeof data.rating === "number"
                ? data.rating.toLocaleString("en-US", {
                    maximumFractionDigits: 1,
                  })
                : data.rating}
            </Rating>
          )}
        </Footer>
      </Content>
    </Container>
  );
}

export default ArticleCard;
