import styled, { css } from "styled-components";

export const AccordionItem = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #0e121b08;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  overflow: hidden;
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ $isOpen }) => ($isOpen ? "23px 23px 12px" : "23px")};
  cursor: pointer;
  background: #ffffff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f9fafb;
  }
`;

export const AccordionTitle = styled.h3`
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #0d0d12;
  margin: 0;
  padding-right: 24px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const AccordionIcon = styled.img.attrs({
  src: "/images/chevron-down-icon-black.svg",
})`
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: transform 0.2s ease;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AccordionContent = styled.div`
  padding: ${({ $isOpen }) => ($isOpen ? "0 23px 70px" : "0 23px")};
  max-height: ${({ $isOpen }) => ($isOpen ? "1000px" : "0")};
  opacity: ${({ $isOpen }) => ($isOpen ? "1" : "0")};
  transition: all 0.2s ease-in-out;
  overflow: auto;
  color: #737373;
  font-size: 16px;

  &,
  * {
    font-family: Inter !important;
    letter-spacing: -0.02em !important;
    line-height: 1.5em !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  p {
    margin: 6px 0 16px 0;
  }

  ul,
  ol {
    padding-left: 30px;

    li + li {
      margin-top: 8px;
    }
  }

  @media (max-width: 767px) {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            padding-bottom: 53px;
          `
        : ""};

    font-size: 14px;
  }
`;
