import React from "react";

import { NavButton, NavigationContainer, PageIndicator } from "./styles";

const NavigationControls = ({ currentStep, totalSteps, onPrev, onNext }) => (
  <NavigationContainer>
    <NavButton onClick={onPrev} disabled={currentStep === 1}>
      <img src="/images/arrow-left-black.svg" alt="Previous step" />
    </NavButton>
    <PageIndicator>
      {currentStep}/{totalSteps}
    </PageIndicator>
    <NavButton onClick={onNext} disabled={currentStep === totalSteps}>
      <img src="/images/arrow-right-black.svg" alt="Next step" />
    </NavButton>
  </NavigationContainer>
);

export default NavigationControls;
