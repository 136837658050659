import React from "react";

import EmailSubscribeForm from "../../components/EmailSubscribeForm";

import { Root, Content, Title, Highlight, Subtitle, Form } from "./styles";

const Hero = ({ landingId, onSubscribe }) => {
  return (
    <Root>
      <Content>
        <Title>
          45% Email Engagement Rates
          <br />
          In <Highlight>Just 5 Steps</Highlight>
        </Title>
        <Subtitle>
          Build and launch Personalized email campaigns in minutes with just the
          free version of Hubspot
        </Subtitle>

        <Form>
          <EmailSubscribeForm
            landingId={landingId}
            onSuccess={onSubscribe}
            buttonText="Get Started"
            isYellow={true}
          />
        </Form>
      </Content>
    </Root>
  );
};

export default Hero;
