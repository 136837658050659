import React from "react";
import {
  PromoContainer,
  PromoContent,
  PromoTitle,
  PromoText,
  AnimationContainer,
  TryButton,
  TextContainer,
  Message,
} from "./styles";
import Animation from "./Animation";
import { CHROME_STORE_EXTENSION_URL } from "../../../utils/constants";
import { useInView } from "react-intersection-observer";

const PromoSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <PromoContainer ref={ref}>
      <PromoContent>
        <TextContainer>
          <PromoTitle>From Spreadsheets to Stunning Emails</PromoTitle>
          <PromoText>
            Create Personalized email Campaigns from a spreadsheet for free- No
            Uploads, No Templates, No Hassle
          </PromoText>
          <TryButton href={CHROME_STORE_EXTENSION_URL} target="_blank">
            Try Reach
          </TryButton>
          <Message>No credit card required!</Message>
        </TextContainer>
        <AnimationContainer>
          <Animation isStarted={inView} />
        </AnimationContainer>
      </PromoContent>
    </PromoContainer>
  );
};

export default PromoSection;
