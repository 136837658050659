import styled, { css } from "styled-components";

export const Container = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  position: relative;

  ${({ $forResource }) =>
    $forResource
      ? css`
          width: 100%;

          &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            left: -1px;
            height: 30%;
            width: calc(100% + 1px);
            background: linear-gradient(180deg, #47116800 0%, #391952 100%);
            z-index: 1;

            animation-name: appear-c;
            animation-iteration-count: 1;
            animation-delay: 2.2s;
            animation-duration: 1s;
            animation-fill-mode: forwards;
          }

          @keyframes appear-c {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0.65;
              height: 50%;
            }
          }
        `
      : ""}

  img {
    width: 100%;
  }

  *,
  *::before,
  *::after {
    animation-play-state: ${(props) =>
      props.$isStarted ? "running" : "paused"};
  }
`;

export const SelectionArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 37.4%;
    left: 0;
    height: 0px;
    width: 0px;
    background-color: #fbff00;
    opacity: 0.3;
    animation-name: select-table;
    animation-iteration-count: 1;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes select-table {
    0% {
      height: 0;
      width: 0;
    }
    100% {
      height: 59%;
      width: 97.9%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 89%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("/images/feat-3-1.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 75%;
    top: 11.5%;
    left: 0;
    opacity: 0;

    animation-name: appear;
    animation-iteration-count: 1;
    animation-delay: 2.2s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Cursor = styled.div`
  width: 48px;
  height: 49px;
  mask: url("/images/cursor.svg") no-repeat center;
  position: absolute;
  top: 92.4%;
  left: 95.6%;
  background-color: #000;
  transform: scale(1.5);
  animation-name: move-table-cursor;
  animation-iteration-count: 1;
  animation-duration: 2s;

  @keyframes move-table-cursor {
    0% {
      top: 77%;
      left: 48%;
    }
    50% {
      top: 34%;
      left: -2%;
    }
    100% {
      top: 92.4%;
      left: 95.6%;
    }
  }
`;
