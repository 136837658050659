import { fetchLandingPage } from "./queries";

const getLandingPage = async (name, isQaEnv) => {
  const result = await fetchLandingPage(name, isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

export { getLandingPage };
