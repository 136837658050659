import React, { useRef, forwardRef } from "react";

import TabItem from "./TabItem";

import { List } from "./styles";

const TabsList = forwardRef(
  ({ list, selectedTab, handleTabClick, isOpen, setIsOpenTabList }, ref) => {
    const tabRefs = useRef(
      Object.fromEntries(list.map((tab) => [tab.key, null])),
    );

    const handleRef = (element, tabKey) => {
      return (tabRefs.current[tabKey] = element);
    };

    return (
      <div ref={ref}>
        <List $isOpen={isOpen}>
          {list.map((tab) => (
            <TabItem
              key={tab.id}
              name={tab.name}
              link={tab.link}
              submenu={tab.submenu}
              onClick={() => {
                handleTabClick(tab);
                if (tab.submenu) {
                  return;
                }
                setIsOpenTabList(false);
              }}
              ref={(element) => handleRef(element, tab.key)}
              tabRef={tabRefs.current[tab.key]}
              isActive={selectedTab && tab && selectedTab.id === tab.id}
              setIsOpenTabList={setIsOpenTabList}
            />
          ))}
        </List>
      </div>
    );
  },
);

export default TabsList;
