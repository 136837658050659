import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import Slide from "./Slide";

import { Section, Title } from "./styles";
import { HOMEPAGE_ANCHOR_CONTAINER_IDs } from "../../../utils/constants";

function TestimonialsContainer() {
  const swiperRef = useRef(null);

  const comments = [
    {
      user: {
        name: "Joe Famalette",
        jobTitle: "Founder, Creator Growth Group",
        imageType: 4,
        imageAlt:
          "Reach by SalesStream.ai - Free AI Email Generator - Testimonial by Joe Famalette",
      },
      text: "Reach has been a pivotal tool for Creator Growth Group. Its ability to personalize outreach, automate follow-ups, and track performance has significantly enhanced our ability to connect with creators, nurture relationships, and drive tangible results.",
    },
    {
      user: {
        name: "Sarah Galperin",
        jobTitle: "Realtor, Sarah Sells South Florida",
        imageType: 3,
        imageAlt:
          "Reach by SalesStream.ai - Free AI Email Generator - Testimonial by Sarah Galperin",
      },
      text: "Being a busy real estate agent, Reach has been a lifesaver. It’s helped me automate tedious tasks, personalize my outreach, and ultimately, connect with more clients. It’s a must-have for any real estate agent looking to streamline their workflow and close more deals.",
    },
    {
      user: {
        name: "Anatoly Vaysman, DDS",
        jobTitle: "Founder, Anatoly Vaysman, DDS",
        imageType: 1,
        imageAlt:
          "Reach by SalesStream.ai - Free AI Email Generator - Testimonial by Anatoly Vaysman",
      },
      text: "Reach? More like reach for the stars! This tool has been a total game-changer for my dental practice. It’s helped me automate the boring stuff, like follow-ups, so I can focus on what I love: teeth! Plus, it’s super easy to use, even for a tech-challenged dentist like me.",
    },
    {
      user: {
        name: "Will Khashkes",
        jobTitle: "Head of Growth, CBidds",
        imageType: 2,
        imageAlt:
          "Reach by SalesStream.ai - Free AI Email Generator - Testimonial by Will Kashkes",
      },
      text: "Reach has been huge for us at CBidds. It’s helped us streamline our outreach, personalize our pitch, and close more deals. Seriously, it’s like having a personal assistant that’s always on point.",
    },
    {
      user: {
        name: "Ruvin Spivak, CIPP-E",
        jobTitle: "Attorney",
        imageType: 5,
        imageAlt:
          "Reach by SalesStream.ai - Free AI Email Generator - Testimonial by Ruvin Spivak",
      },
      text: "Reach has streamlined my client communications by helping me create personalized, professional emails quickly. As a privacy attorney, I value its seamless integration with my CRM and secure data handling, which allows me to focus on clients rather than client outreach.",
    },
  ];

  const onSelectUser = (index) => {
    if (!swiperRef.current) {
      return;
    }

    swiperRef.current.swiper.slideTo(index);
  };

  return (
    <Section id={HOMEPAGE_ANCHOR_CONTAINER_IDs.testimonials.key}>
      <Title>What users say about Reach</Title>
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        navigation={true}
        spaceBetween={50}
        slidesPerView={1}
      >
        {comments.map((commentInfo, i) => (
          <SwiperSlide key={i}>
            <Slide
              comments={comments}
              commentInfo={commentInfo}
              onSelectUser={onSelectUser}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
}

export default TestimonialsContainer;
