import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fffcf5;
  position: relative;
`;

export const HeroSection = styled.div`
  color: #ffffff;
  background-color: #391952;
  position: relative;
  width: 100%;
  height: 813px;
  padding: 136px 15px 0 15px;

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      rgba(204, 204, 204, 0.1) 1.5px,
      transparent 0
    );
    background-size: 20px 20px;
  }

  @media (max-width: 768px) {
    height: 710px;
    padding-top: 96px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -190px;
    width: 100%;
    height: 190px;
    background-color: #fff3d6;
  }

  @media (max-width: 768px) {
    height: 710px;
    padding-top: 96px;

    &::after {
      bottom: -84px;
      height: 84px;
    }
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 120px;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const StaticPromoSectionContainer = styled.div`
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
