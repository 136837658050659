import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  max-width: 620px;
  display: flex;
  gap: 12px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || "40px"};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  flex: 1;
  height: ${({ $isFlat }) => ($isFlat ? "52px" : "48px")};
  padding: ${({ $isFlat }) => ($isFlat ? "16.5px" : "14.5px")} 25px;
  background: #ffffff;
  border: none;
  border-radius: 12px;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 400;
  font-size: 15px;
  line-height: 1em;
  outline: none;
  box-shadow: 0px 4px 4px 0px #00000040 inset;

  &::placeholder {
    color: #cccccc;
    font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
    font-weight: 400;
    font-size: 15px;
    line-height: 1em;
  }
`;

export const Button = styled.button`
  height: ${({ $isYellow }) => ($isYellow ? "52px" : "48px")};
  padding: 0 ${({ $isYellow }) => ($isYellow ? "29px" : "45px")};
  border: none;
  border-radius: 12px;
  color: #391952;
  background: ${({ $isYellow }) => ($isYellow ? "#FFDC61" : "#f9ac59")};
  font-family: ${({ theme }) => theme.fonts.poppinsPrimary};
  font-size: 16px;
  font-weight: ${({ $isYellow }) => ($isYellow ? 700 : 600)};
  line-height: 1em;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #ffd53e;
  }
`;

export const Message = styled.p`
  margin: 0;
  display: block;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 1.5em;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`;
