import styled from "styled-components";

export const PromoContainer = styled.div`
  width: calc(100% - 32px);
  background: #391952;
  border-radius: 24px;
  margin-top: 51px;

  @media (max-width: 768px) {
    margin-top: 16px;
    width: 100%;
    border-radius: 16px;
  }
`;

export const PromoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 104px;
  gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 32px;
    gap: 64px;
  }
`;

export const AnimationContainer = styled.div`
  width: calc(50% - 40px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  width: calc(50% - 40px);
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PromoTitle = styled.h2`
  color: #ffffff;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.poppinsPrimary};
  font-weight: 700;
  font-size: 48px;
  line-height: 1.25em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const PromoText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin: 12px 0 57px;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-right: 65px;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 32px;

    br {
      display: none;
    }
  }
`;

export const FormContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
