import { useEffect } from "react";

export const useClickOutside = (ref, callback, excludeClassNames = []) => {
  useEffect(() => {
    function handleClickOutside(event) {
      const isClickOutside =
        ref?.current && !ref.current.contains(event.target);
      const isNotExcluded = !excludeClassNames.some((className) =>
        event.target?.classList?.contains(className),
      );

      if (isClickOutside && isNotExcluded) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, callback, excludeClassNames]);
};
