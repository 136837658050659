import { useEffect } from "react";

export const useHeaderScroll = (wrapperRef, isNavbarVisible) => {
  useEffect(() => {
    if (!isNavbarVisible) return;
    if (!wrapperRef?.current) return;

    const updateHeaderOnScroll = () => {
      if (window.scrollY > 0) {
        wrapperRef.current.classList.add("isSticky");
      } else {
        wrapperRef.current.classList.remove("isSticky");
      }
    };

    updateHeaderOnScroll();
    window.addEventListener("scroll", updateHeaderOnScroll);
    return () => window.removeEventListener("scroll", updateHeaderOnScroll);
  }, [isNavbarVisible, wrapperRef]);
};
