import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 24px;
  width: 100%;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 16px 32px -12px #0e121b1a;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    ${({ $order }) =>
      $order === 1
        ? css`
            border-radius: 16px;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          `
        : $order === 2
        ? css`
            border-radius: 16px;
            grid-column-start: 2;
            grid-row-start: 1;
            grid-row-end: 2;
            flex-direction: row;
          `
        : $order === 3
        ? css`
            border-radius: 16px;
            grid-column-start: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            flex-direction: row;
          `
        : ""}
  }

  @media (max-width: 767px) {
    border-radius: 16px;
  }
`;

export const Content = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    border-radius: 12px;
  }

  @media (min-width: 768px) {
    ${({ $order }) =>
      $order === 1
        ? css`
            img {
              height: 296px;
            }
          `
        : $order === 2 || $order === 3
        ? css`
            img {
              width: 256px;
              height: 200px;
            }
          `
        : ""}
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Tag = styled.div`
  padding: 4px 9px;
  width: fit-content;
  height: fit-content;
  min-height: 28px;
  border-radius: 8px;
  background: #ebf3ff;
  color: #3e89ff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

export const Title = styled.div`
  margin-top: 18px;
  margin-bottom: 16px;
  width: fit-content;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #0e121b;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    ${({ $order }) =>
      $order === 1
        ? css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          `
        : $order === 2 || $order === 3
        ? css`
            margin-top: 16px;
          `
        : ""}
  }

  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

export const Meta = styled.div`
  margin-top: auto;
  padding: 2px 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #717784;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
`;

export const MetaSeparator = styled.span`
  min-width: 4px;
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 50%;
`;
