import styled, { css } from "styled-components";

export const List = styled.ul`
  padding: 4px;
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 0 6px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(16px);
  margin-bottom: 25px;
  position: relative;
  width: 520px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ListItem = styled.li`
  cursor: pointer;
  padding: 12px 23px;
  background-color: ${({ $isActive }) =>
    $isActive ? "#612686" : "transparent"};
  border-radius: 100px;
  width: 97px;
`;

export const Text = styled.span`
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -3%;

  ${({ $isActive }) =>
    $isActive
      ? css`
          color: #fff;
        `
      : css`
          color: rgba(255, 255, 255, 0.7);
        `}
`;
