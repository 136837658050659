import React, { useState, useEffect, useRef } from "react";

import { Container, Tab } from "./styles";
import { FEATURE_TAB_LIST } from "../../../../utils/constants";

const FeatureTabs = ({ progress, onSetFeature }) => {
  const container = useRef(null);
  const [activeFeature, setActiveFeature] = useState(0);

  useEffect(() => {
    progress.on("change", () => {
      const index =
        progress.current > 0.6 ? 3 : Math.floor(progress.current * 1.67 * 4);

      setActiveFeature(index);
    });
  }, []);

  return (
    <Container ref={container} className="FeatureTabsContainer">
      {FEATURE_TAB_LIST.map((tab) => {
        return (
          <Tab
            id={tab.key}
            key={tab.id}
            $isActive={activeFeature === tab.id}
            onClick={() => onSetFeature(tab.id)}
          >
            {tab.title}
          </Tab>
        );
      })}
    </Container>
  );
};

export default FeatureTabs;
