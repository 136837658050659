import React from "react";

import { Container, Cursor, SelectionArea } from "./styles";

const ThirdAnimation = ({ isStarted, forResource }) => {
  return (
    <Container $isStarted={isStarted} $forResource={forResource}>
      <img
        src="/images/feat-3.png"
        alt="Reach by SalesStream.ai - Free AI Email Generator - Send personalized email from Spreadsheet"
      />
      <Cursor />
      <SelectionArea $forResource={forResource} />
    </Container>
  );
};

export default ThirdAnimation;
