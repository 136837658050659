import styled from "styled-components";

const AddedToWaitListMessage = ({ recordsCount, color, smallSize = false }) => {
  return (
    <FooterContainer>
      <img
        src={`/images/success-icon-${color}.png`}
        alt="success"
        width={smallSize ? "29" : "61"}
      />
      <TextBlock>
        <Title $color={color} $smallSize={smallSize}>
          Thanks for joining us!
        </Title>
        <Description $color={color} $smallSize={smallSize}>
          We'll keep you posted on everything Reach!
        </Description>
      </TextBlock>
    </FooterContainer>
  );
};

export default AddedToWaitListMessage;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.div`
  color: ${({ $color, theme }) => theme.colors[$color]};
  font-size: ${({ $smallSize, theme }) =>
    $smallSize ? theme.fontSizes.medium : theme.fontSizes.text};
  font-weight: 700;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

const Description = styled.div`
  color: ${({ $color, theme }) => theme.colors[$color]};
  font-size: ${({ $smallSize, theme }) =>
    $smallSize ? theme.fontSizes.xSmall : theme.fontSizes.medium};
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.xSmall};
  }
`;
