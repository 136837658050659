import React from "react";

import { List, ListItem, Text } from "./styles";

function TabList({ tabs, activeTab, onTabClick }) {
  const handleTabClick = (tabId) => {
    onTabClick(tabId);
  };

  return (
    <List>
      {tabs.map((tab) => (
        <ListItem
          key={tab.id}
          $isActive={activeTab === tab.id}
          onClick={() => handleTabClick(tab.id)}
        >
          <Text $isActive={activeTab === tab.id}>{tab.title}</Text>
        </ListItem>
      ))}
    </List>
  );
}

export default TabList;
