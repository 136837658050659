import React from "react";
import {
  PromoContainer,
  PromoContent,
  PromoTitle,
  PromoText,
  ImageContainer,
  LogoContainer,
  StartCampaignButton,
} from "./styles";
import { CHROME_STORE_EXTENSION_URL } from "../../../../utils/constants";

const StartCampaignPromo = () => {
  return (
    <PromoContainer>
      <PromoContent>
        <LogoContainer>
          <img src="/images/full-logo-new.png" alt="logo" />
        </LogoContainer>
        <PromoTitle>
          No CRM?
          <br />
          No Problem.
        </PromoTitle>
        <PromoText>
          Build Impactful Email & SMS campaigns from your spreadsheet
        </PromoText>
      </PromoContent>
      <ImageContainer>
        <img
          src="/images/feat-3-new-2.svg"
          alt="Reach by SalesStream.ai - Free AI Email Generator - Send personalized email from Spreadsheet"
        />
      </ImageContainer>
      <StartCampaignButton href={CHROME_STORE_EXTENSION_URL}>
        Try Reach for Free
      </StartCampaignButton>
    </PromoContainer>
  );
};

export default StartCampaignPromo;
