const Logo = ({ color, width, isClickable, onClick }) => {
  return (
    <img
      src={`/images/full-logo-${color}.png`}
      alt="logo reach"
      width={width}
      style={isClickable && { cursor: "pointer" }}
      onClick={onClick}
    />
  );
};

export default Logo;
