import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 850px;
    background-color: #391952;

    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    top: 220px;
    left: 0;
    width: 100%;
    height: 630px;
    background-image: radial-gradient(
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px
    );
    background-size: 24px 24px;
    z-index: 0;
  }
`;

export const HeroSection = styled.div`
  width: 100%;
  color: #ffffff;
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 120px;
  position: relative;
  z-index: 1;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }
`;
