import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 40px;
  max-width: 1000px;
  max-height: 575px;
  box-shadow: 0px 16px 32px -12px #0e121b1a;
  aspect-ratio: 1000 / 575;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    max-height: initial;
    height: auto;
    aspect-ratio: auto;
  }
`;

export const Content = styled.div`
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 24px;
    height: auto;
  }
`;

export const Step = styled.p`
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -3%;
  color: #0a0a0a;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &::before {
    content: "";
    background-image: url(/images/arrow-right-black.svg);
    width: 14px;
    height: 14px;
    padding-right: 20px;
    display: block;
    background-repeat: no-repeat;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
