import styled from "styled-components";

export const PromoContainer = styled.div`
  padding: 32px;
  width: 100%;
  background: #391952;
  border-radius: 24px;

  @media (max-width: 767px) {
    border-radius: 16px;
  }
`;

export const PromoContent = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    position: relative;
    flex-direction: column;
    row-gap: 64px;
  }
`;

export const AnimationContainer = styled.div`
  max-width: 528px;
  flex: 1;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: flex-end;
  }
`;

export const TextContainer = styled.div`
  padding: 32px;
  max-width: 528px;
  flex: 1;
  order: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0;
    max-width: 100%;
    width: 100%;
  }
`;

export const PromoTitle = styled.div`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 48px;
  line-height: 1.25em;
  font-weight: 700;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 32px;
  }

  @media (max-width: 767px) {
    padding-right: 5px;
  }
`;

export const PromoText = styled.div`
  margin-top: 12px;
  padding-right: 30px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: -0.02em;
  text-align: left;

  @media (max-width: 767px) {
    padding-right: 5px;
  }
`;

export const TryButton = styled.a`
  text-decoration: none;
  background: #ffffff;
  border-radius: 12px;
  padding: 18px 21px;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 700;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #612686;
  border: none;
  cursor: pointer;
  width: fit-content;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 17px;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const Message = styled.div`
  margin-top: 16px;
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 1.5em;
  color: rgba(255, 255, 255, 0.7);
`;
