import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -340px auto 80px auto;
  padding: 0 24px;

  @media (max-width: 768px) {
    width: 100%;
    margin: -90px auto 24px auto;
  }
`;
