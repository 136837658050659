import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProductHuntLink = styled.a`
  display: block;
  width: 210px;
  height: 45px;
`;

export const StarsContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

export const StarsBackground = styled.div`
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
  font-size: 33.27px;
  line-height: 24px;
`;

export const StarsForeground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  white-space: nowrap;
  overflow: hidden;
  width: ${(props) => props.$rating * 20}%;
  font-size: 33.27px;
  line-height: 24px;
  background: #ffda56;
  -webkit-background-clip: text;
  background-clip: text;
`;

export const RatingText = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  font-family: Poppins;
  letter-spacing: 7%;
`;
