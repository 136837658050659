import React from "react";
import { useMediaQuery } from "react-responsive";
import { waitForElement } from "../../../utils/utils";
import DesktopSubMenu from "./Desktop";
import MobileSubMenu from "./Mobile";

function SubMenu({ data, setIsOpenTabList, isActive, isSubmenuVisible }) {
  const handleSubmenuLinkClick = async (key, isAnchor) => {
    setIsOpenTabList(false);

    if (!isAnchor) return;

    const section = await waitForElement(key);

    if (section) {
      if (section.tagName === "LI") {
        section.click();
      } else {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 977 });

  if (isMobile) {
    return (
      <MobileSubMenu
        data={data}
        handleSubmenuLinkClick={handleSubmenuLinkClick}
      />
    );
  }

  return (
    <DesktopSubMenu
      data={data}
      isActive={isActive}
      isSubmenuVisible={isSubmenuVisible}
      handleSubmenuLinkClick={handleSubmenuLinkClick}
    />
  );
}

export default SubMenu;
