import React from "react";
import {
  ArticleCardContainer,
  ArticleCard,
  ArticleContent,
  ArticleImageContainer,
  ArticleSubTitle,
  ArticleTitle,
  Container,
  Description,
  Title,
  AccessButton,
} from "./styles";
import { prepareGuideTitle } from "../../../utils/utils";

function Benefits({ data, onClick }) {
  return (
    <Container>
      <Title>
        The Best collection of Guides <br /> to Grow your Small Business
      </Title>
      <Description>
        Get answers to all your small business growth queries in one place
      </Description>
      {!!data && (
        <ArticleCardContainer>
          <ArticleCard>
            <ArticleImageContainer>
              <img
                src={data.illustration || "/images/placeholder-blog-image.svg"}
                alt="Illustration"
              />
            </ArticleImageContainer>
            <ArticleContent>
              <ArticleTitle>{`${prepareGuideTitle(
                data.pageHeaderText,
              )} Guide`}</ArticleTitle>
              <ArticleSubTitle>
                {data.description ||
                  data.shortDescription ||
                  `Get Answers to all your ${data.pageHeaderText.toLowerCase()} queries in one place`}
              </ArticleSubTitle>
              <AccessButton onClick={onClick}>Access Guides</AccessButton>
            </ArticleContent>
          </ArticleCard>
        </ArticleCardContainer>
      )}
    </Container>
  );
}

export default Benefits;
