import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 45px;
  background-color: #000000;

  @media (max-width: 768px) {
    border-radius: 16px;
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 45px;

  @media (max-width: 768px) {
    border-radius: 16px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 45px;

    @media (max-width: 768px) {
      border-radius: 16px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(57, 25, 82, 0.55);
    border-radius: 45px;
    z-index: 1;

    @media (max-width: 768px) {
      border-radius: 16px;
    }
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 64px;
  width: 64px;
  background: #fff;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 2;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &::before {
    content: "";
    position: absolute;
    height: 88px;
    width: 88px;
    background: rgba(255, 255, 255, 0.3);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #ffffff40;
  }

  &::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    background: transparent;
    transform: translate(-6px, -50%);
    border: 12px solid transparent;
    border-right: 18px solid transparent;
    border-left: 18px solid #391952;
  }
`;

export const Video = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 45px;

  @media (max-width: 768px) {
    border-radius: 16px;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 45px;

    @media (max-width: 768px) {
      border-radius: 16px;
    }
  }
`;
