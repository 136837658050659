import React, { useState } from "react";
import { Button, Container, Input, SearchIcon } from "./styles";

const Search = ({ onSearch }) => {
  const [value, setValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const val = e.target.value.trim();
      onSearch(val);
      setValue(val);
      try {
        e.target.blur();
      } catch {}
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    setValue(e.target.value.trim());
  };

  const handleClickSearch = (e) => {
    e?.preventDefault();
    onSearch(value);
  };

  return (
    <Container>
      <Input
        placeholder="I want to read about..."
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <SearchIcon />
      <Button type="button" onClick={handleClickSearch}>
        Search
      </Button>
    </Container>
  );
};

export default Search;
