const PRODUCT_HUNT_API = "https://api.producthunt.com/v2/api/graphql";
const POST_ID = "692375";

export const fetchProductRating = async () => {
  try {
    const response = await fetch(PRODUCT_HUNT_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_PRODUCT_HUNT_TOKEN}`,
      },
      body: JSON.stringify({
        query: `
          query {
            post(id: "${POST_ID}") {
              votesCount
              reviewsRating
            }
          }
        `,
      }),
    });

    const data = await response.json();
    if (data.data?.post?.reviewsRating) {
      return data.data.post.reviewsRating;
    }

    return 4.7; // Fallback rating if API call fails
  } catch (error) {
    console.error("Failed to fetch Product Hunt rating:", error);
    return 4.7; // Fallback rating
  }
};
