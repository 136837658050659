import styled from "styled-components";

export const SubscribeContainer = styled.div`
  padding: 108px 11.3% 86px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 75px 15px;
    align-items: center;
  }
`;

export const Logo = styled.img.attrs({
  src: "/images/full-logo-pink.png",
  alt: "Reach by SalesStream.ai logo - Free AI Email Generator",
})`
  height: 75px;

  @media (max-width: 767px) {
    height: 53px;
  }
`;

export const Title = styled.h6`
  margin: 22px 0 0;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 44px;
  font-weight: 800;
  line-height: 53px;
  letter-spacing: -0.04em;

  @media (max-width: 767px) {
    margin: 16px 24px 0 31px;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
  }
`;

export const Description = styled.div`
  margin: 6px 0 25px;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 767px) {
    margin: 0 24px 19px 31px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
`;
