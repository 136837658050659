import { useState, useEffect } from "react";
import { getFeaturedPost } from "../../../services/blog";

export const useFeaturedPost = (isQaEnv = false) => {
  const [featuredPost, setFeaturedPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFeaturedPost = async () => {
      setIsLoading(true);

      const response = await getFeaturedPost(isQaEnv);

      if (!response.success) {
        setIsLoading(false);
        return;
      }

      setFeaturedPost(response.post);

      setIsLoading(false);
    };

    fetchFeaturedPost();
  }, [isQaEnv]);

  return { featuredPost, isLoading };
};

export default useFeaturedPost;
