import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Wrapper } from "../../pages/HomeLanding/styles";
import Footer from "../../pages/HomeLanding/Footer";
import {
  BackToHomeBtn,
  BackToHomeContainer,
  HeroContent,
  HeroLastUpd,
  HeroSection,
  HeroTitle,
  MainContent,
  MainSection,
} from "./styles";
import { FIXED_HEADER_PATHS } from "../../utils/constants";

const TextPage = ({ title, content, contentElement, lastUpdated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const shouldAbsolutePositionBackToHome = FIXED_HEADER_PATHS.some((path) =>
    location.pathname.startsWith(path),
  );

  const handleClickBackToHome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <Wrapper>
      <BackToHomeContainer
        $shouldAbsolutePositionBackToHome={shouldAbsolutePositionBackToHome}
      >
        <BackToHomeBtn onClick={handleClickBackToHome}>
          <img src="/images/back.svg" alt="back" />
          Back to home
        </BackToHomeBtn>
      </BackToHomeContainer>
      <HeroSection>
        <HeroContent>
          <HeroTitle>{title}</HeroTitle>
          {!!lastUpdated && (
            <HeroLastUpd>
              Last updated on <span>{lastUpdated}</span>
            </HeroLastUpd>
          )}
        </HeroContent>
      </HeroSection>
      <MainSection>
        {contentElement ? (
          <MainContent>{contentElement}</MainContent>
        ) : (
          <MainContent
            className="ql-editor" // Added because classes have nesting ".ql-editor .{class_name}".
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </MainSection>
      <Footer forPrivacyAndTerms />
    </Wrapper>
  );
};

export default TextPage;
