import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledMenuItem = styled.span`
  position: relative;
`;

export const SubmenuList = styled.ul`
  position: absolute;
  top: 25px;
  left: 0;
  padding: 4px 12px;
  list-style-type: none;
  border-radius: 5px;
  background-color: #391952c7;

  @media (max-width: 977px) {
    border: 1px solid #612686;
    background-color: #391952;
    left: unset;
    right: 0;
    z-index: 12;
  }
`;

export const SubmenuItem = styled.li`
  white-space: nowrap;

  @media (max-width: 977px) {
    margin: 5px 0;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Name = styled.span`
  font-family: Inter;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgb(255, 255, 255);
`;
