import React, { useEffect } from "react";
import Hero from "./Hero";
import Features from "../components/Features";
import Integrations from "../components/Integrations";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import { Root, HeroSection, Main } from "./styles";
import Header from "../components/Header";

const KillingBusinessLanding = ({ landingInfo, onSubscribe }) => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      // Mouse movement handling will be added later
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <Root>
      <Header onSubscribe={onSubscribe} />
      <HeroSection>
        <Hero landingId={landingInfo.id} onSubscribe={onSubscribe} />
      </HeroSection>
      <Main>
        <Features />
        <Integrations />
        <Subscribe landingId={landingInfo.id} onSubscribe={onSubscribe} />
      </Main>
      <Footer />
    </Root>
  );
};

export default KillingBusinessLanding;
