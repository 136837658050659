import styled from "styled-components";

export const Container = styled.p`
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #fff;

  @media (max-width: 768px) {
    padding: 0 9px;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const TextHighlight = styled.span`
  font-family: ${({ theme }) => theme.fonts.neueMontrealPrimary};
  font-weight: 700;
  transition: opacity 0.5s ease;
  opacity: ${({ $isFade }) => ($isFade ? 0 : 1)};
`;
