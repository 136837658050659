import { useState, useMemo } from "react";

export const useSwiperConfig = (
  relatedArticles = [],
  isMobile = false,
  isTablet = false,
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const slidesPerView = useMemo(() => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 3;
  }, [isMobile, isTablet]);

  const totalSlides = useMemo(() => {
    if (!Array.isArray(relatedArticles)) {
      return 1;
    }

    return Math.max(1, Math.ceil(relatedArticles.length / slidesPerView));
  }, [relatedArticles, slidesPerView]);

  const calculatePageFromSlideIndex = (activeIndex) => {
    return Math.min(totalSlides, Math.floor(activeIndex / slidesPerView) + 1);
  };

  return {
    slidesPerView,
    totalSlides,
    currentPage,
    setCurrentPage,
    calculatePageFromSlideIndex,
  };
};
