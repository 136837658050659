import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Root,
  ProductHuntLink,
  StarsContainer,
  StarsBackground,
  StarsForeground,
  RatingText,
} from "./styles";
import { fetchProductRating } from "../../../../services/productHunt";

const Rating = ({ isDark = false }) => {
  const [rating, setRating] = useState(5);

  useEffect(() => {
    const loadRating = async () => {
      const fetchedRating = await fetchProductRating();
      setRating(fetchedRating);
    };

    loadRating();
  }, []);

  return (
    <Root>
      <ProductHuntLink
        href="https://www.producthunt.com/posts/reach-from-salesstream-ai?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-reach&#0045;from&#0045;salesstream&#0045;ai"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={`https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=692375&theme=light&period=daily`}
          alt="Reach from SalesStream.ai - World's simplest free-forever email & SMS campaign builder | #3 Product of the day_Product Hunt"
          style={{ width: "210px", height: "45px" }}
          width="210"
          height="45"
        />
      </ProductHuntLink>
      <StarsContainer>
        <StarsBackground>★★★★★</StarsBackground>
        <StarsForeground $rating={rating}>★★★★★</StarsForeground>
      </StarsContainer>
      <RatingText>{rating.toFixed(1)}</RatingText>
    </Root>
  );
};

Rating.propTypes = {
  isDark: PropTypes.bool,
};

export default Rating;
