import React, { useEffect } from "react";
import Hero from "./Hero";
import Features from "../Features";
import Integrations from "../Integrations";
import Footer from "../Footer";
import { Root, HeroSection, Main } from "./styles";
import Header from "../Header";
import StaticPromoSection from "../../../../components/StaticPromoSection";

const MarketingLanding = ({
  title,
  landingInfo,
  onSubscribe,
  isExtensionInstalled,
}) => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      // Mouse movement handling will be added later
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <Root>
      <Header
        isForHubspot={true}
        onSubscribe={onSubscribe}
        isExtensionInstalled={isExtensionInstalled}
      />
      <HeroSection>
        <Hero
          title={title}
          landingId={landingInfo.id}
          landingInfo={landingInfo}
          onSubscribe={onSubscribe}
        />
      </HeroSection>
      <Main>
        <Features variant="scroll" />
        <Integrations variant="framed" />
        <StaticPromoSection
          landingId={landingInfo.id}
          onSubscribe={onSubscribe}
        />
      </Main>
      <Footer />
    </Root>
  );
};

export default MarketingLanding;
