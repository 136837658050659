import React, { useRef, useEffect, useContext } from "react";
import {
  StepsContainer,
  ScrollContainer,
  Step,
  StepTitle,
  StepDescription,
  StepItem,
  StepImage,
  StepText,
} from "./styles";
import { AppContext } from "../../../../contexts/AppContext";

const ScrollVariant = ({ stepsInfo, variant }) => {
  const scrollContainerRef = useRef(null);
  let { lenis } = useContext(AppContext);

  const scroll = (event) => {
    event.preventDefault();

    /** @type {Element} */
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }

    const scrollAmount = event.deltaY * 2;
    const newScrollPosition = scrollContainer.scrollLeft + scrollAmount;

    const isScrollInLeft = scrollAmount < 0 && scrollContainer.scrollLeft <= 0;

    const isScrollInRight =
      scrollAmount > 0 &&
      scrollContainer.scrollLeft >=
        scrollContainer.scrollWidth - scrollContainer.clientWidth;

    if (isScrollInLeft || isScrollInRight) {
      return;
    }

    lenis.stop();
    scrollContainer.scrollTo({
      left: newScrollPosition,
      behavior: "smooth",
    });

    lenis.start();
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("mouseenter", () => {
        window.addEventListener("wheel", scroll, { passive: false });
      });
      scrollContainer.addEventListener("mouseleave", () => {
        window.removeEventListener("wheel", scroll, { passive: false });
      });
    }

    return () => {
      if (scrollContainer) {
        window.removeEventListener("scroll", scroll);
      }
    };
  }, []);

  return (
    <ScrollContainer ref={scrollContainerRef}>
      <StepsContainer variant={variant}>
        {stepsInfo.steps.map((step, index) => (
          <Step key={index} number={index + 1} variant={variant}>
            <StepImage variant={variant}>
              <img
                src={`${stepsInfo.imagesPath}/${index + 1}.png`}
                alt={step.title}
              />
            </StepImage>
            <StepText variant={variant}>
              <StepTitle variant={variant} $number={index + 1}>
                {step.title}
              </StepTitle>
              <StepDescription variant={variant}>
                {step.description.map((item, index) => (
                  <StepItem key={index} variant={variant}>
                    {item}
                  </StepItem>
                ))}
              </StepDescription>
            </StepText>
          </Step>
        ))}
      </StepsContainer>
    </ScrollContainer>
  );
};

export default ScrollVariant;
