import styled from "styled-components";

export const PromoContainer = styled.div`
  padding: 24px;
  width: 100%;
  background: #391952;
  border-radius: 16px;
  margin-top: 40px;
  color: #fff;
`;

export const PromoContent = styled.div``;

export const LogoContainer = styled.div`
  width: 105px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  img {
    width: 100%;
    height: auto;
  }
`;

export const PromoTitle = styled.div`
  margin: 40px 0 0;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
`;

export const PromoText = styled.div`
  margin: 16px 0 0;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
`;

export const ImageContainer = styled.div`
  margin-top: 53px;
  width: 100%;
  height: 203px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30%;
    width: 100%;
    background: linear-gradient(180deg, #47116800 0%, #391952 100%);
  }
`;

export const StartCampaignButton = styled.a`
  margin: 39px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #fff;
  color: #612686;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
`;
