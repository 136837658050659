import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 64px;
  width: 100%;

  @media (max-width: 767px) {
    margin: 0 9px;
    width: calc(100% - 9px * 2);
  }
`;

export const Input = styled.input`
  padding: 12px 110px 12px 52px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: none !important;
  outline: none !important;
  background-color: #ffffff !important;
  box-shadow: 0px 16px 32px -12px #0e121b1a !important;
  color: #717784;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;

  &::placeholder {
    color: #717784b2;
  }

  &:focus {
    box-shadow: 0px 16px 32px -12px #0e121b1a, 0 0 1px #0000002a !important;
  }
`;

export const SearchIcon = styled.img.attrs({
  src: "/images/search.svg",
  alt: "Search",
})`
  position: absolute;
  top: calc((100% - 20px) / 2);
  left: 24px;
  width: 20px;
  height: 20px;
`;

export const Button = styled.button`
  width: 86px;
  height: 40px;
  border-radius: 8px;
  border: none !important;
  outline: none !important;
  background: #612686 !important;
  box-shadow: none !important;
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.interPrimary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc((100% - 40px) / 2);
  right: 12px;

  &:hover {
    opacity: 0.9;
  }
`;
